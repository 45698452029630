import { MutableRefObject, RefObject, useEffect } from "react";

const useOnClickOutside = (
  elementRef:
    | RefObject<HTMLElement>
    | MutableRefObject<HTMLElement>
    | (RefObject<HTMLElement> | MutableRefObject<HTMLElement>)[],
  onClick: () => void,
  enabled?: boolean
): void => {
  useEffect(() => {
    if (!elementRef || !("current" in elementRef)) {
      return;
    }

    const handleClick = (event: MouseEvent) => {
      if (!Array.isArray(elementRef) && elementRef.current) {
        if (elementRef.current.contains(event.target as HTMLElement)) {
          return;
        } else if (elementRef.current !== undefined) {
          return onClick();
        }
      }
      if (elementRef && Array.isArray(elementRef)) {
        const elements = elementRef
          .filter((el) => el.current)
          .map((el) => el.current);

        if (elements.find((el) => el?.contains(event.target as HTMLElement))) {
          return;
        } else {
          return onClick();
        }
      }
    };
    if (enabled === undefined || enabled === true) {
      document.addEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [enabled, elementRef, onClick]);
};

export { useOnClickOutside };
