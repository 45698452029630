import { FC } from "react";
import { sanitize } from "isomorphic-dompurify";
import { Locale } from "lib/Intl";

interface Props {
  locale: Locale | undefined;
  nonce: string;
}

const langToHotJarPropIds: Record<string, string> = {
  en: "3559915",
  fr: "3559903",
  de: "3559760",
  "nl-be": "2857905",
  "pl-pl": "3559909",
  "it-it": "3559905",
  nl: "1721704",
};

const HotjarScript: FC<Props> = ({ locale, nonce }) => {
  if (!locale || (locale && !(locale in langToHotJarPropIds))) {
    return null;
  }
  const hotJarPropertyId = langToHotJarPropIds[locale];

  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: sanitize(
          `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hotJarPropertyId},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `,
          {
            KEEP_CONTENT: true,
          }
        ),
      }}
    />
  );
};

export { HotjarScript };
