import { onError } from "@apollo/client/link/error";
import sentry from "./sentry";
import { SessionError } from "pages/api/auth/getUserTokenFromApi";
import { isNativeApp } from "src/utils/isNativeApp";
import { signOut } from "src/utils/nextAuth";
import { removeAccessToken } from "src/utils/removeAccessToken";
const { captureException } = sentry();

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors || networkError) {
      const errMessage = response?.errors?.[0]?.message;
      captureException({
        message: new Error(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          JSON.stringify(`Error link ${errMessage || response?.errors}`)
        ),
        errorClass: "Apollo Error Link",
        graphQLErrors: JSON.stringify(graphQLErrors, null, 2),
        networkError: JSON.stringify(networkError, null, 2),
        response: JSON.stringify(response, null, 2),
        operation: JSON.stringify(operation, null, 2),
      });
    }

    if (networkError) {
      if (
        typeof window !== "undefined" &&
        typeof window.newrelic !== "undefined"
      )
        window.newrelic.noticeError(networkError, { ...operation });

      console.error("Network error occurred:", networkError);
      // eslint-disable-next-line no-console
      console.dir(networkError, { depth: null });
    }

    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        const statusCode =
          (typeof error?.extensions?.code === "number" &&
            error.extensions.code) ||
          (typeof error?.extensions?.status_code === "number" &&
            error.extensions.status_code) ||
          0;
        if (
          typeof window !== "undefined" &&
          typeof window.newrelic !== "undefined"
        )
          window.newrelic.noticeError(error, { statusCode, ...operation });

        if (
          error.extensions?.name === SessionError.INVALID_TOKEN_ERROR ||
          error.extensions?.name === SessionError.EXPIRED_TOKEN_ERROR
        ) {
          if (!isNativeApp) {
            void signOut();
            void removeAccessToken();
          }
        }

        console.error("GraphQL Error occurred:", error);
        // eslint-disable-next-line no-console
        console.dir(error, { depth: null });
      }
    }
    return undefined;
  }
);

export default errorLink;
