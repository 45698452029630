import { ReactNode, useEffect, useState } from "react";
import {
  init,
  getInstance,
  IAssignmentLogger,
  IAssignmentEvent,
} from "@eppo/js-client-sdk";
import { useSession } from "next-auth/client";
import {
  EppoFeatureFlagContext,
  EPPO_FEATURE_FLAG_CONTEXT_DEFAULT_VALUE,
} from "src/contexts/eppoFeatureFlag.context";
import {
  defaultFeatureFlags,
  featureFlagsConfig,
} from "src/featureFlagsConfig/config";
import {
  EppoFeatureFlags,
  FeatureFlagName,
  FeatureFlagOption,
} from "src/types/featureFlags";
import { useSegment } from "src/hooks/useSegment";
import { OTRIUM_NO_GATE_ENABLED } from "src/constants/gatedItem";

function getAnonymousId() {
  let anonId = localStorage.getItem("anonId");
  if (!anonId) {
    anonId = "anon_" + Math.random().toString(36).substr(2, 9);
    localStorage.setItem("anonId", anonId);
  }
  return anonId;
}

export const EppoRandomizationProvider = ({
  children,
  locale,
}: {
  children: ReactNode;
  locale: string;
}): JSX.Element => {
  const { eppoRandomizedAssigment } = useSegment();
  const [featureFlags, setFeatureFlags] = useState<EppoFeatureFlags>(
    EPPO_FEATURE_FLAG_CONTEXT_DEFAULT_VALUE
  );
  const [initialized, setInitialized] = useState<boolean>(false);
  const [featureFlagsInitialized, setFeatureFlagsInitialized] =
    useState<boolean>(false);
  const [session] = useSession();

  useEffect(() => {
    if (!initialized || !session || featureFlagsInitialized) {
      return;
    }

    const initializeEppo = () => {
      const subjectKey = session?.user?.user_id || getAnonymousId();
      const isGYesEnabled = localStorage.getItem(OTRIUM_NO_GATE_ENABLED);

      if (!subjectKey) {
        return;
      }

      try {
        let filteredFlags = featureFlagsConfig;
        if (!session.user.user_id) {
          const filteredConfig = Object.entries(featureFlagsConfig).filter(
            ([key]) => !key.toLowerCase().includes("carousel")
          );
          filteredFlags = Object.fromEntries(filteredConfig);
        }
        const eppoClient = getInstance();
        const fetchedFlags = Object.entries(filteredFlags).reduce(
          (acc: EppoFeatureFlags, [flagName, config]) => {
            const {
              flagKey,
              options = {},
              defaultValue,
            } = config as FeatureFlagOption;

            options.locale = locale;

            if (
              flagName === "pdpCarouselRecommender" ||
              flagName === "pdpTrendingCarouselRecommender" ||
              flagName === "homepageNewInCarouselRecommender" ||
              flagName === "cartYMALCarouselRecommender" ||
              flagName === "cartAlertBanner"
            ) {
              options.email = session?.user?.email || "";
            }

            let flagValue;

            switch (typeof defaultValue) {
              case "string":
                flagValue = eppoClient.getStringAssignment(
                  subjectKey,
                  flagKey,
                  options as Record<string, any>
                );
                break;
              case "object":
                flagValue = eppoClient.getParsedJSONAssignment(
                  subjectKey,
                  flagKey,
                  options as Record<string, any>
                );
                break;
              default:
                flagValue = eppoClient.getBoolAssignment(
                  subjectKey,
                  flagKey,
                  options as Record<string, any>
                );
                break;
            }

            flagValue =
              flagName === "enableGatedHomepage" && isGYesEnabled
                ? false
                : flagValue;

            acc[flagName as FeatureFlagName] = flagValue;

            return acc;
          },
          defaultFeatureFlags
        );

        setFeatureFlagsInitialized(true);
        setFeatureFlags(fetchedFlags);
      } catch (error) {
        console.error(error);
      }
    };

    initializeEppo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, locale, session]);

  useEffect(() => {
    if (initialized) {
      return;
    }

    const assignmentLogger: IAssignmentLogger = {
      logAssignment(assignment: IAssignmentEvent) {
        void eppoRandomizedAssigment(assignment);
      },
    };

    init({
      apiKey: process.env.EPPO_API_KEY || "",
      assignmentLogger,
    })
      .then(() => setInitialized(true))
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eppoRandomizedAssigment]);

  return (
    <EppoFeatureFlagContext.Provider value={featureFlags}>
      {children}
    </EppoFeatureFlagContext.Provider>
  );
};
