import Cookies from "js-cookie";

interface AddToCartQueue {
  variationId: string;
  productId: string;
}

const COOKIE_NAME = "otrium_cookie_product_added";

export const addPostLoginAddedProduct = ({
  variationId,
  productId,
}: AddToCartQueue): boolean =>
  !!Cookies.set(COOKIE_NAME, { variationId, productId });

export const popPostLoginAddedProduct = (): AddToCartQueue | undefined => {
  const data = Cookies.getJSON(COOKIE_NAME) as AddToCartQueue;
  Cookies.remove(COOKIE_NAME);
  return data;
};
