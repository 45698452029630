import { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError, ApolloQueryResult, gql } from "@apollo/client";
import { useSession } from "next-auth/client";
import { OrderQueryResult } from "src/types/ctgraphql.d";
import { AppCtx } from "src/contexts/app.context";
import { moneyFragment, addressFragment } from "./useCart";

export interface MyOrdersVars {
  locale: string;
  storeKey: string;
  limit: number;
}

interface MyOrdersHook {
  data: { myOrders: OrderQueryResult | undefined };
  loading: boolean;
  error?: ApolloError;
  refetch: (
    variables?: MyOrdersVars | undefined
  ) => Promise<ApolloQueryResult<MyOrdersData>>;
}
export interface MyOrdersData {
  me: { orders: OrderQueryResult };
}

const orderFragment = gql`
  fragment order on Order {
    orderNumber
    createdAt
    orderState
    paymentState
    customer {
      email
      firstName
      lastName
      addresses {
        phone
        mobile
        email
      }
    }
    totalPrice {
      ...money
    }
    taxedPrice {
      totalNet {
        ...money
      }
      totalGross {
        ...money
      }
      totalTax {
        ...money
      }
    }
    paymentInfo {
      payments {
        paymentStatus {
          interfaceCode
          interfaceText
        }
        paymentMethodInfo {
          method
          name(acceptLanguage: [$locale, "en"])
        }
      }
    }
    shippingAddress {
      ...address
    }
    billingAddress {
      ...address
    }
    shipmentState
    shippingInfo {
      deliveries {
        items {
          id
        }
        parcels {
          items {
            id
          }
          trackingData {
            carrier
            trackingId
          }
          custom {
            customFieldsRaw {
              name
              value
            }
          }
        }
      }
      shippingMethod {
        localizedName(locale: $locale)
      }
      price {
        ...money
      }
      discountedPrice {
        value {
          ...money
        }
      }
    }
    returnInfo {
      returnDate
      returnTrackingId
    }
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    discountCodes {
      discountCodeRef {
        typeId
        id
      }
      state
      discountCode {
        code
        isActive
        name(locale: $locale)
        description(locale: $locale)
        cartDiscounts {
          id
          target {
            type
          }
          value {
            ... on AbsoluteDiscountValue {
              money {
                ...money
              }
            }
            ... on FixedPriceDiscountValue {
              money {
                ...money
              }
            }
            ... on RelativeDiscountValue {
              permyriad
            }
            ... on GiftLineItemValue {
              variantId
            }
          }
        }
      }
    }
    lineItems {
      id
      name(acceptLanguage: [$locale, "en"])
      productSlug(acceptLanguage: [$locale, "en"])
      quantity
      price {
        value {
          ...money
        }
      }
      state {
        state {
          name(locale: $locale)
        }
      }
      variant {
        id
        attributesRaw {
          name
          value
        }
      }
      discountedPricePerQuantity {
        quantity
        discountedPrice {
          value {
            ...money
          }
          includedDiscounts {
            discountedAmount {
              ...money
            }
            discount {
              id
              key
            }
          }
        }
      }
    }
    customLineItems {
      slug
      totalPrice {
        ...money
      }
    }
  }

  ${moneyFragment}
  ${addressFragment}
`;

const ORDERS = gql`
  query orders($locale: Locale!, $limit: Int!) {
    me {
      orders(sort: "custom.fields.order_created_at desc", limit: $limit) {
        results {
          ...order
        }
      }
    }
  }

  ${orderFragment}
`;

const useMyOrders = (
  limit = 50,
  onCompleted?: (myOrderData: MyOrdersData) => void
): MyOrdersHook => {
  const [session] = useSession();
  const { storeKey, storeConfig } = useContext(AppCtx);

  const { loading, error, data, refetch } = useQuery<
    MyOrdersData,
    MyOrdersVars
  >(ORDERS, {
    variables: {
      locale: storeConfig.locale,
      storeKey,
      limit,
    },
    context: { useCT: true },
    ssr: true,
    fetchPolicy: "no-cache",
    skip: !session,
    onCompleted,
  });

  return { loading, error, data: { myOrders: data?.me.orders }, refetch };
};

export { useMyOrders };
