// INDEXES
export const BRANDS = "brands";
export const CATEGORIES = "categories";
export const PRODUCTS = "products";
export const SUGGESTIONS = "suggestions";

// INDEXES REPLICAS
export const PRODUCTS_PRICE = {
  ASC: `${PRODUCTS}_price_asc`,
  DESC: `${PRODUCTS}_price_desc`,
};
export const PRODUCTS_PUBLISH_DATE = {
  ASC: `${PRODUCTS}_publish_date_asc`,
  DESC: `${PRODUCTS}_publish_date_desc`,
};
export const PRODUCTS_DISCOUNT_DATE = {
  ASC: `${PRODUCTS}_discount_date_asc`,
  DESC: `${PRODUCTS}_discount_date_desc`,
};

// FACETS
export const BRAND_FACET = "brand.meta";
export const BRAND_SLUG_FACET = "brand.slug";
export const SIZE_FACET = "container_sizes.meta";
export const PRICE_FACET = "price";
export const COLOUR_FACET = "color.meta";
export const AGES_FACET = "ages.meta";
export const PATTERN_FACET = "pattern.meta";
export const STYLE_FACET = "style.meta";
export const PROMOTION_FACET = "promotion_filter.meta";
export const CONSCIOUS_FACET = "brand.good_on_you_info.score_meta";
export const GENDERS_FACET = "genders";
export const CATEGORIES_NAME_FACET = "categories.name";
export const CATEGORIES_FACET = "categories.categoryPageId_v2";
export const CATEGORIES_SLUG_FACET = "categories.slug";
export const BRAND_DEPARTMENTS_FACET = "brand.departments";
export const LABELS_TYPE_FACET = "labels.type";
export const PROMOTION_ID_FACET = "promotion_filter.id";
export const IS_CLEARANCE_PRODUCT_FACET = "is_clearance_product";
export const BRAND_DESIGNER_ONLY_FACET = "brand.designer_only";
export const COLLECTIONS_FACET = "collections";
export const BRAND_IS_PRIVATE_SALE = "brand.is_private_sale";
export const IS_DELETED = "is_deleted";
export const SLUG_FACET = "slug";
export const DISCOUNT_FACET = "discount_buckets.meta";
export const CATEGORY_PAGE_ID_FACET = "category_page_id";
