import { RequestOptions } from "@algolia/transporter";
import {
  MultipleQueriesOptions,
  MultipleQueriesQuery,
  MultipleQueriesResponse,
  StrategyType,
} from "@algolia/client-search";
import { useContext, useEffect, useState } from "react";
import { AlgoliaContext } from "src/contexts/algolia.context";

interface AlgoliaMultipleRequestVars {
  queries: MultipleQueriesQuery[];
  strategy?: StrategyType;
  requestOptions?: RequestOptions & MultipleQueriesOptions;
}

interface AlgoliaSearchMultipleRequestsHook<T> {
  data: MultipleQueriesResponse<T> | undefined;
  loading: boolean;
  error: unknown;
}

const useAlgoliaMultipleRequests = <T>({
  queries,
  requestOptions,
  strategy = "none",
}: AlgoliaMultipleRequestVars): AlgoliaSearchMultipleRequestsHook<T> => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>(null);

  const [searchResults, setSearchResults] = useState<
    MultipleQueriesResponse<T> | undefined
  >(undefined);
  const { client } = useContext(AlgoliaContext);

  useEffect(() => {
    if (!client) {
      return;
    }

    const getResponse = async () => {
      try {
        const response = await client.multipleQueries<T>(queries, {
          strategy,
          ...(requestOptions || {}),
        });

        setSearchResults(response);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    void getResponse();
  }, [queries, client, requestOptions, strategy]);

  return {
    data: searchResults,
    loading,
    error,
  };
};

export default useAlgoliaMultipleRequests;
