import { NoSearchResults } from "src/molecules/NoSearchResults";
import useGetAlgoliaRecommendedProducts from "src/organisms/Search/useGetAlgoliaRecommendedProducts";
import { SearchWidget } from "src/molecules/SearchWidget";
import { Box } from "@otrium/core";
import { ProductCardWishlist } from "src/organisms/ProductCardWishlist";
import { Trans } from "@lingui/macro";
import { CatalogBrand, ShopType } from "src/types/graphql.d";
import getFullIndexName from "src/utils/algolia/getFullIndexName";
import { Dispatch, useCallback, useContext, useMemo } from "react";
import { AppCtx } from "src/contexts/app.context";
import { useSegmentProductList } from "src/hooks/useSegmentProductList";
import {
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_ID,
  SEGMENT_LIST_TYPE,
} from "src/segment";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import { GATED_POPUP_REDIRECT_DATA } from "src/constants/gatedItem";
import { ProductRedirect } from "../ProductCardWishlist/ProductCardWishlist";
import { AbandonedTrackerActions } from "../Header/SubMenu";
import { getSearchResultDisplayedProps } from "./utils";
import { useSegment } from "src/hooks/useSegment";
import { AlgoliaSearchSuggestion } from "src/types/Algolia";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";

interface NoSearchOverlayResults {
  searchTerm: string;
  shopType: ShopType;
  triggerClose: () => void;
  dispatchAbandonedTracker: Dispatch<AbandonedTrackerActions>;
}

const NoSearchOverlayResults = ({
  searchTerm,
  shopType,
  triggerClose,
  dispatchAbandonedTracker,
}: NoSearchOverlayResults): JSX.Element => {
  const { isGhostDomain, locale } = useContext(AppCtx);
  const prefix = isGhostDomain ? "zz" : locale;
  const productsIndexName = getFullIndexName({ indexName: "products", prefix });
  const { data } = useGetAlgoliaRecommendedProducts(shopType);
  const recommendedProducts = data?.results || [];

  // segment section
  const listFormat = SEGMENT_LIST_FORMAT.search;
  const listType = SEGMENT_LIST_TYPE.products;
  const listId = SEGMENT_LIST_ID.search;
  const { segmentProductOnClick, setVisibleProducts } = useSegmentProductList({
    algoliaIndex: productsIndexName,
    listFormat,
    listId,
    listType,
    numberOfItems: 0,
    queryID: data?.meta.queryID,
    shopType,
  });
  const { searchResultClicked } = useSegment();
  const isLoggedIn = useIsLoggedIn();

  const setRedirectData = (
    type: string,
    product?: ProductRedirect,
    actionSource?: string,
    position?: number
  ) => {
    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: type,
      numberOfItems: recommendedProducts?.length || 0,
      product,
      productListMeta: {
        listFormat,
        listId,
        listType,
        shopType,
      },
      actionSource,
      position,
    });
  };
  const { searchResultDisplayedProps } = useMemo(() => {
    const searchResultDisplayedProps = getSearchResultDisplayedProps({
      searchTerm,
      products: recommendedProducts,
      suggestions: [],
    });

    return { products: recommendedProducts, searchResultDisplayedProps };
  }, [recommendedProducts, searchTerm]);

  const SEARCH_CLICK_TYPE_MAP: Record<
    Exclude<AlgoliaSearchSuggestion["type"], "information"> | "product",
    string
  > = {
    brand: "brand_id_clicked",
    category: "category_id_clicked",
    collection: "collections_id_clicked",
    product: "product_id_clicked",
  };

  const handleRecomendedProductClick = useCallback(
    (
      position: number,
      itemId: string,
      type: Exclude<AlgoliaSearchSuggestion["type"], "information"> | "product",
      gateLevel?: string
    ) => {
      dispatchAbandonedTracker({ type: "SET_USER_INTERACTED", payload: true });

      void searchResultClicked({
        ...searchResultDisplayedProps,
        position_clicked: position,
        gate_level: gateLevel,
        [SEARCH_CLICK_TYPE_MAP[type]]: itemId,
        gated: !isLoggedIn,
      });

      triggerClose();
    },
    [triggerClose, dispatchAbandonedTracker]
  );

  return (
    <>
      <NoSearchResults term={searchTerm} />
      {recommendedProducts.length > 0 && (
        <SearchWidget
          data-testid="search-recommended-products"
          header={<Trans>Recommended for you</Trans>}
          pt={5}
          pb={3}
        >
          {recommendedProducts.map((product, index) => (
            <Box px={[2, 2, 2, 2, 0]} key={product.objectID}>
              <ProductCardWishlist
                {...product}
                product={{
                  ...product,
                  id: product.objectID,
                  name: product.name,
                  price: product.price,
                  regular_price: product.regular_price,
                  brand: {
                    name: product.brand_name || "",
                  } as CatalogBrand,
                  thumbnail: product.image || "",
                  labels: [],
                  available_sizes_label: "",
                  images: product.brand?.images || [],
                  gate: product.gate,
                }}
                position={index}
                segmentProductOnClick={segmentProductOnClick}
                setVisibleProducts={setVisibleProducts}
                onSearchProductClick={handleRecomendedProductClick}
                queryID={data?.meta.queryID}
                algoliaIndex={productsIndexName}
                setProductRedirect={setRedirectData}
              />
            </Box>
          ))}
        </SearchWidget>
      )}
    </>
  );
};

export default NoSearchOverlayResults;
