import { FC, SVGAttributes } from "react";

const Filtering: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g transform="translate(-16 -230) translate(0 162) translate(16 64) translate(0 4) translate(0 2)">
      <rect width="12" height="2" x="4" y="2" rx="1" />
      <rect width="9" height="2" y="9" rx="1" />
      <rect width="3" height="2" x="13" y="9" rx="1" />
      <path d="M3 0c1.657 0 3 1.343 3 3S4.657 6 3 6 0 4.657 0 3s1.343-3 3-3zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM11 7c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" />
    </g>
  </svg>
);

export { Filtering };
