import sentry from "lib/sentry";
// eslint-disable-next-line import/no-unresolved
import { NextApiRequest } from "next-auth/internals/utils";
import cookie from "cookie";
import { Mutation } from "src/types/graphql.d";
import { REQUEST_SOURCE, REQUEST_SOURCE_TYPE } from "src/config";
import { isNativeApp } from "src/utils/isNativeApp";
import { getStoreyKey } from "src/utils/storeConfig";

interface Error {
  message: string;
  locations: [];
  path: [];
  extensions: Record<string, unknown>;
}

interface MutationResponse {
  data?: {
    facebookUserLoginV2?: Mutation["facebookUserLoginV2"];
    googleUserLoginV2?: Mutation["googleUserLoginV2"];
    appleUserLoginV2?: Mutation["appleUserLoginV2"];
    userLoginV2?: Mutation["userLoginV2"];
    guestLoginV2?: Mutation["guestLoginV2"];
    userRegister?: Mutation["userRegister"];
    softLogin?: Mutation["softLogin"];
    shopTokenLogin?: Mutation["shopTokenLogin"];
  };
  errors?: Error[];
}

export enum AuthProvider {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  APPLE = "apple",
  CREDENTIALS = "credentials",
  CREDENTIALS_REGISTER = "credentials-register",
  // NOTE: Keep these fields in sync with AUTH_PROVIDER__CREDENTIALS_SOFT_LOGIN and
  // AUTH_PROVIDER__GUEST_LOGIN from middlewares/utils/authProvider.js
  CREDENTIALS_SOFT_LOGIN = "soft_login",
  GUEST_LOGIN = "guest-login",
  WOO_SHOP_TOKEN = "woo-shop-token",
}

export enum SessionError {
  REFRESH_ACCESS_TOKEN_ERROR = "RefreshAccessTokenError",
  INVALID_TOKEN_ERROR = "TokenInvalidError",
  EXPIRED_TOKEN_ERROR = "TokenExpiredError",
}

interface Credentials {
  name?: string;
  email?: string;
  password?: string;
  grant_type?: string;
  gender?: string;
  wooShopToken?: string;
  // TODO: Delete 'shopPreferences' and use 'pref_[gender]' for Shop Preferences as this is
  // the format of API request
  //
  // NOTE: Next Auth signIn() method transforms "credentials" values into strings using "new URLSearchParams".
  // Source: https://github.com/nextauthjs/next-auth/blob/main/src/client/index.js#L208
  shopPreferences?: string;
  isEmployeeSale?: string;
  isNativeApp?: string;
}

const getTokenFromApi = async (
  provider: AuthProvider,
  accountToken: string | null,
  credentials: Credentials | null,
  req: NextApiRequest
): Promise<MutationResponse | null | undefined> => {
  const accessToken = req.cookies.ot_auth_token;
  const locale = `${(process.env.LOCALE as string) || req.cookies.locale}`;
  const storeKey = `${
    (process.env.STORE_KEY as string) || getStoreyKey(req.cookies.locale)
  }`;

  const headers = {
    "content-type": "application/json",
    "Accept-language": locale,
    "Accept-StoreKey": storeKey,
    "Request-Source": REQUEST_SOURCE,
    ...(isNativeApp && { "Request-Source-Type": REQUEST_SOURCE_TYPE }),
    authorization: accessToken ? `Bearer ${accessToken}` : "",
  };

  if (provider === AuthProvider.GOOGLE) {
    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `mutation {
            googleUserLoginV2(google_token: "${accountToken}") {
              token
            }
          }`,
        }),
      });
      const response = (await data.json()) as MutationResponse;
      return response;
    } catch (err) {
      console.log("Google Log in error:", err);

      if (window.NREUM) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.NREUM.noticeError(new Error(`Google Log in error: ${err}`));
      }
    }
  }

  if (provider === AuthProvider.FACEBOOK) {
    const { captureException } = sentry();

    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `mutation {
            facebookUserLoginV2(fb_token: "${accountToken}") {
              token
            }
          }`,
        }),
      });
      const response = (await data.json()) as MutationResponse;

      if (response.errors) {
        captureException(response.errors[0]);
      }

      return response;
    } catch (err) {
      console.log("Facebook Log in error", err);
      if (window.NREUM) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.NREUM.noticeError(new Error(`Facebook Log in error: ${err}`));
      }
    }
  }

  if (provider === AuthProvider.APPLE) {
    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `mutation {
            appleUserLoginV2(identity_token: "${accountToken}") {
              token
            }
          }`,
        }),
      });
      const response = (await data.json()) as MutationResponse;
      return response;
    } catch (err) {
      console.log("Apple Log in error:", err);

      if (window.NREUM) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.NREUM.noticeError(new Error(`Apple Log in error: ${err}`));
      }
    }
  }

  if (provider === AuthProvider.CREDENTIALS && credentials) {
    const { email, password } = credentials;

    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `mutation {
              userLoginV2(
                email: "${email}",
                password: "${password}"
              ) {
                token
              }
            }`,
        }),
      });
      const response = (await data.json()) as MutationResponse;
      return response;
    } catch (err) {
      console.log("error calling gql query email login", err);

      if (window.NREUM) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.NREUM.noticeError(new Error(`Email Log in error: ${err}`));
      }
    }
  }

  if (
    provider === AuthProvider.CREDENTIALS_SOFT_LOGIN &&
    credentials?.shopPreferences
  ) {
    const { email, shopPreferences } = credentials;
    const shopPreferencesArray = shopPreferences.split(",");

    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `mutation {
              softLogin(
                email: "${email}",
                shop_preference: [${shopPreferencesArray
                  .map((value) => `"${value}"`)
                  .toString()}],
                source_type: "web"
              ) {
                member_id
                email
                gender
                shop_preference
                opt_in
                new_signup
                user_token {
                  token
                }
              }
            }`,
        }),
      });
      const response = (await data.json()) as MutationResponse;
      return response;
    } catch (err) {
      console.log("error calling gql query softLogin", err);

      if (window.NREUM) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.NREUM.noticeError(new Error(`Soft Log in error: ${err}`));
      }
    }
  }

  if (provider === AuthProvider.CREDENTIALS_REGISTER && credentials) {
    const { name, email, password } = credentials;

    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `mutation {
              userRegister(
                name: "${name}",
                email: "${email}",
                password: "${password}",
                source: "web",
                source_type: "web",
                language: "${process.env.LOCALE as string}",
              ) {
                user_id
                member_id
                email
                gender
                opt_in
                name
                onboarding_metadata {
                  stage
                  tries
                  status
                  show_onboarding
                }
                user_token {
                  token
                }
              }
            }`,
        }),
      });
      const response = (await data.json()) as MutationResponse;
      return response;
    } catch (err) {
      console.log("error calling gql query userRegister", err);

      if (window.NREUM) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.NREUM.noticeError(
          new Error(`Credential Register error: ${err}`)
        );
      }
    }
  }

  if (provider === AuthProvider.WOO_SHOP_TOKEN && credentials) {
    const { wooShopToken, isNativeApp } = credentials;
    const cookieHeader = wooShopToken
      ? cookie.serialize("ot_token", wooShopToken)
      : req.headers.cookie || "";

    const webViewHeader = !headers["Request-Source-Type"] &&
      !!isNativeApp && { "Request-Source-Type": REQUEST_SOURCE_TYPE };

    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers: {
          ...headers,
          ...webViewHeader,
          Cookie: cookieHeader,
        },
        body: JSON.stringify({
          query: `mutation {
            shopTokenLogin {
              token
            }
          }`,
        }),
      });
      const response = (await data.json()) as MutationResponse;
      return response;
    } catch (err) {
      console.log(`${AuthProvider.WOO_SHOP_TOKEN} error: `, err);
    }
  }

  if (provider === AuthProvider.GUEST_LOGIN) {
    try {
      const data = await fetch(`${process.env.GRAPHQL_URI as string}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `mutation {
            guestLoginV2(source_type: "source") {
              token
            }
          }`,
        }),
      });

      return (await data.json()) as MutationResponse;
    } catch (err) {
      console.log("Guest Log in  error", err);

      if (window.NREUM) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.NREUM.noticeError(new Error(`Guest Log in  error: ${err}`));
      }
    }
  }

  return null;
};

export default getTokenFromApi;
