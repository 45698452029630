import {
  useCallback,
  ChangeEvent,
  forwardRef,
  Dispatch,
  SetStateAction,
  RefObject,
  useState,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { openSearch } from "src/organisms/Header/duck";
import { CloseIconBig } from "src/icons/CloseIconBig";
import {
  SearchHeader,
  SearchIcon,
  SearchInput,
  SearchCloseButton,
} from "./SearchBar.styled";
import { useBreakpoints } from "@otrium/core";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { useDebounce } from "use-debounce";

interface SearchBarProps {
  isOpenSearch: boolean;
  searchAnimationTime: number | undefined;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  handleCloseSearch: (callback?: () => void) => void;
  closeButtonRef?: RefObject<HTMLButtonElement> | undefined;
}

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      isOpenSearch,
      searchAnimationTime,
      setSearchTerm,
      handleCloseSearch,
      closeButtonRef,
    },
    ref
  ) => {
    const { _ } = useLingui();
    const { isDesktop } = useBreakpoints();
    const [isClient, setIsClient] = useState(false);
    const [value, setValue] = useState<string>("");
    const [debouncedsearchTerm] = useDebounce(value, 400);
    const dispatch = useDispatch();
    const onFocus = useCallback(() => {
      if (!isOpenSearch) {
        dispatch(openSearch());
      }
    }, [dispatch, isOpenSearch]);

    useEffect(() => {
      setIsClient(true);
    }, []);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      },
      [setValue]
    );

    useEffect(() => {
      setSearchTerm(debouncedsearchTerm);
    }, [debouncedsearchTerm, setSearchTerm]);

    const handleClose = useCallback(() => {
      const clearValue = () => setValue("");

      handleCloseSearch(clearValue);
    }, [handleCloseSearch]);

    const showSearchBar =
      !isClient || isDesktop || (!isDesktop && isOpenSearch);

    return showSearchBar ? (
      <SearchHeader
        expand={isOpenSearch}
        duration={searchAnimationTime}
        as="section"
        role="search"
      >
        <SearchIcon />
        <SearchInput
          ref={ref}
          data-testid="search-input"
          type="text"
          placeholder={_(msg`What are you looking for?`)}
          onFocus={onFocus}
          value={value}
          onChange={handleChange}
          autoComplete="force-off"
        />
        {isOpenSearch && (
          <SearchCloseButton
            ref={closeButtonRef}
            onClick={handleClose}
            duration={searchAnimationTime}
            icon={<CloseIconBig />}
          />
        )}
      </SearchHeader>
    ) : null;
  }
);

SearchBar.displayName = "SearchBar";
export { SearchBar };
