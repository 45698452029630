import { baseTheme } from "@otrium/core";
import { BREAKPOINTS } from "src/breakpoints";
import { Theme } from "@emotion/react";

const theme: Theme = {
  ...baseTheme,
  space: [0, 4, 8, 16, 24, 32, 64],
  fontSizes: [14, 16, 20, 24, 32, 48],
  breakpoints: [
    BREAKPOINTS.xs,
    BREAKPOINTS.sm,
    BREAKPOINTS.md,
    BREAKPOINTS.lg,
    BREAKPOINTS.xl,
  ],
  zIndex: {
    header: 1200,
    popup: 1300,
    sticky: 1201,
    overlay: 1425,
    drawer: 1450,
    modal: 1500,
  },
  // @deprecated
  fonts: {
    body: "Source Sans Pro, sans-serif",
    heading: '"PlusJakartaSans", sans-serif',
  },
  // @deprecated
  fontWeights: {
    body: 400,
    heading: 700,
  },
  text: {
    h1: {
      fontSize: ["fontSize32", "fontSize32", "fontSize48"],
      lineHeight: ["40px", "40px", "64px"],
      color: "tone.black",
      fontFamily: "heading",
    },
    h2: {
      fontSize: ["fontSize24", "fontSize24", "fontSize32"],
      lineHeight: ["32px", "32px", "40px"],
      color: "tone.black",
      fontFamily: "heading",
    },
    h3: {
      fontSize: ["fontSize20", "fontSize20", "fontSize24"],
      lineHeight: ["24px", "24px", "32px"],
      color: "tone.black",
      fontFamily: "heading",
    },
    h4: {
      fontSize: ["fontSize18", "fontSize18", "fontSize20"],
      lineHeight: "24px",
      color: "tone.black",
      fontFamily: "heading",
    },
    body: {
      highlight: {
        color: "tone.black",
        fontSize: ["fontSize32", "fontSize32", "fontSize48"],
        lineHeight: ["40px", "40px", "56px"],
        fontWeight: "700",
      },
      regular: {
        big: {
          color: "tone.black",
          fontSize: ["fontSize16", "fontSize16", "fontSize18"],
          lineHeight: ["24px", "24px", "32px"],
          fontWeight: "400",
        },
      },
      source: {
        bold16: {
          color: "tone.black",
          fontSize: "fontSize16",
          lineHeight: "24px",
          fontWeight: "700",
          fontFamily: "body",
        },
        semibold16: {
          fontSize: "fontSize16",
          lineHeight: "24px",
          fontWeight: "600",
          fontFamily: "body",
        },
        regular16: {
          color: "tone.black",
          fontSize: "fontSize16",
          lineHeight: "24px",
          fontWeight: "400",
          fontFamily: "body",
        },
        bold14: {
          color: "tone.black",
          fontSize: "fontSize14",
          lineHeight: "20px",
          fontWeight: "700",
          fontFamily: "body",
        },
        semibold14: {
          color: "tone.black",
          fontSize: "fontSize14",
          lineHeight: "20px",
          fontWeight: "600",
          fontFamily: "body",
        },
        regular14: {
          color: "tone.black",
          fontSize: "fontSize14",
          lineHeight: "20px",
          fontWeight: "400",
          fontFamily: "body",
        },
        semibold12: {
          color: "tone.black",
          fontSize: "fontSize12",
          lineHeight: "16px",
          fontWeight: "600",
          fontFamily: "body",
        },
        regular12: {
          color: "tone.black",
          fontSize: "fontSize12",
          lineHeight: "16px",
          fontWeight: "400",
          fontFamily: "body",
        },
      },
    },
  },
  // @deprecated
  socialButton: "#1D1E20",
};

theme.space.space0 = "0px";
theme.space.space4 = "4px";
theme.space.space8 = "8px";
theme.space.space16 = "16px";
theme.space.space24 = "24px";
theme.space.space32 = "32px";
theme.space.space48 = "48px";
theme.space.space64 = "64px";
theme.space.space96 = "96px";

/* eslint-disable no-underscore-dangle */
theme.space._4 = "4px";
theme.space._8 = "8px";
theme.space._12 = "12px";
theme.space._16 = "16px";
theme.space._24 = "24px";
theme.space._32 = "32px";
theme.space._48 = "48px";
theme.space._64 = "64px";
theme.space._96 = "96px";
/* eslint-enable no-underscore-dangle */

theme.fontSizes.fontSize12 = "12px";
theme.fontSizes.fontSize14 = "14px";
theme.fontSizes.fontSize16 = "16px";
theme.fontSizes.fontSize18 = "18px";
theme.fontSizes.fontSize20 = "20px";
theme.fontSizes.fontSize24 = "24px";
theme.fontSizes.fontSize32 = "32px";
theme.fontSizes.fontSize48 = "48px";

theme.breakpoints.xs = "0";
theme.breakpoints.sm = theme.breakpoints[1];
theme.breakpoints.md = theme.breakpoints[2];
theme.breakpoints.lg = theme.breakpoints[3];
theme.breakpoints.xl = theme.breakpoints[4];

export { theme };
