import { Trans } from "@lingui/macro";
import { FC, RefObject, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppCtx } from "src/contexts/app.context";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { HamburgerMenu } from "src/icons/HamburgerMenu";
import { Logo } from "src/icons/Logo";
import { Search } from "src/icons/Search";
import { ShoppingCart } from "src/icons/ShoppingCart";
import { User } from "src/icons/User";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { Content } from "src/atoms/Content";
import { Menu } from "src/atoms/Menu";
import { UserMenu } from "./UserMenu";
import { getGenderId } from "src/modules/page";
import { openNavigationMenu, openSearch } from "./duck";
import {
  StyledNavigation,
  StyledNotificationBadge,
  StyledCartCount,
  StyledNavigationButton,
  StyledLoginButton,
  StyledLogo,
} from "./Navigation.styled";
import Link from "next/link";
import { useUserWishlistMeta } from "src/hooks/useUserWishlistMeta";
import { Box, Flex } from "@otrium/core";
import { useRouter } from "next/router";
import { GenderSelect } from "./GenderSelect";
import { Heart } from "src/icons/Heart";
import { EMPLOYEE_SALE_BRAND_SLUG } from "const/employeeSaleBrands";
import { useCart } from "src/hooks/useCart";
import { cartCountHelper } from "src/utils/ct/cartCountHelper";
import { theme } from "src/theme";
import { SearchBlackIcon } from "src/icons";
import { useIsScrollAtPosition } from "src/hooks/useIsScrollAtPosition";

interface Props {
  className?: string;
  isLoginFlow: boolean;
  gendersRef?: RefObject<HTMLDivElement>;
  simplifiedHeader?: boolean;
}

const Navigation: FC<Props> = ({
  className,
  isLoginFlow,
  gendersRef,
  simplifiedHeader = false,
}) => {
  const router = useRouter();
  const isLoginPage = router.pathname.includes("sign-in");
  const { data: userWishlistMetaData } = useUserWishlistMeta();
  const genderId = useSelector(getGenderId);
  const isLoggedIn = useIsLoggedIn();
  const { data: cartData } = useCart();
  const { locale, isPrivateSale } = useContext(AppCtx);

  const cartCount = cartCountHelper(cartData.cart?.lineItems);

  const dispatch = useDispatch();
  const handleOpenNavigationMenu = useCallback(
    () => dispatch(openNavigationMenu()),
    [dispatch]
  );
  const handleOpenSearch = useCallback(
    () => dispatch(openSearch()),
    [dispatch]
  );

  const showSearchButton = useIsScrollAtPosition({
    scrollDirection: "vertical",
    validator: (scrollPos) => scrollPos > 48,
  });

  const showNotificationBadge =
    userWishlistMetaData?.userWishlistMetaData &&
    userWishlistMetaData?.userWishlistMetaData.wishlist_product_count > 0;

  return (
    <StyledNavigation className={className}>
      <Content>
        <Flex
          justifyContent={simplifiedHeader ? "center" : "space-between"}
          height={[58, 58, 58, 64]}
        >
          {!simplifiedHeader && (
            <Flex flex="1" alignItems="center">
              <Box display={["block", "block", "block", "none"]}>
                <StyledNavigationButton
                  data-testid="navigation-button"
                  onClick={handleOpenNavigationMenu}
                >
                  <HamburgerMenu />
                </StyledNavigationButton>
              </Box>

              {!isLoginFlow && (
                <>
                  <Box ml={4} display={["block", "block", "block", "none"]}>
                    <StyledNavigationButton
                      data-testid="main-header-search-btn"
                      onClick={handleOpenSearch}
                    >
                      <Search stroke={theme.colors.tone.black} />
                    </StyledNavigationButton>
                  </Box>

                  <Box display={["none", "none", "none", "block"]}>
                    <GenderSelect locale={locale} ref={gendersRef} isInHeader />
                  </Box>
                </>
              )}
              {isPrivateSale && (
                <Box display={["none", "none", "none", "none", "block"]}>
                  <GenderSelect locale={locale} ref={gendersRef} isInHeader />
                </Box>
              )}
            </Flex>
          )}

          {!isLoginFlow && genderId && (
            <Link
              href={
                isPrivateSale
                  ? `${getI18nRoute({
                      route: `/brands`,
                      locale,
                    })}/${EMPLOYEE_SALE_BRAND_SLUG}${getI18nRoute({
                      route: `/${genderId}`,
                      locale,
                    })}`
                  : getI18nRoute({
                      route: `/${genderId as string}`,
                      locale,
                    })
              }
              passHref
              legacyBehavior
            >
              <Box
                as="a"
                data-testid="main-header-logo"
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "100%",
                }}
              >
                <p className="sr-only">Otrium home page</p>
                <Logo width={98} />
              </Box>
            </Link>
          )}
          {!isLoginFlow && !genderId && (
            <StyledLogo>
              <Logo width={98} data-testid="main-header-logo" />
            </StyledLogo>
          )}
          {!simplifiedHeader && (
            <>
              {!isLoginFlow ? (
                <Flex flex="1" justifyContent="flex-end">
                  <Flex
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                      position: "relative",
                    }}
                  >
                    {!["/not-found", "/my-profile", "/my-orders"].includes(
                      router.route
                    ) && showSearchButton ? (
                      <Box ml={4} display={["none", "none", "none", "block"]}>
                        <StyledNavigationButton
                          data-testid="main-header-search-btn"
                          onClick={handleOpenSearch}
                        >
                          <SearchBlackIcon />
                        </StyledNavigationButton>
                      </Box>
                    ) : null}

                    <Box display={["none", "none", "block"]}>
                      <Menu>
                        <Menu.Button>
                          {({ onToggle }) => (
                            <Box ml={4}>
                              <StyledNavigationButton
                                variant="secondary"
                                data-testid="main-header-my-account-button"
                                onClick={onToggle}
                              >
                                <User />
                              </StyledNavigationButton>
                            </Box>
                          )}
                        </Menu.Button>
                        <Menu.List className="navigation__userMenu">
                          <UserMenu />
                        </Menu.List>
                      </Menu>
                    </Box>

                    <Link
                      href={
                        isLoggedIn
                          ? "/favourites"
                          : "/sign-in?redirect_to=/favourites"
                      }
                      as={
                        isLoggedIn
                          ? "/favourites"
                          : "/sign-in?redirect_to=/favourites"
                      }
                      passHref
                      legacyBehavior
                    >
                      <Box
                        as="a"
                        data-testid="main-header-favourites-a"
                        ml={4}
                        display="flex"
                      >
                        <Heart
                          stroke={theme.colors.tone.black}
                          strokeWidth="2"
                        />
                        {showNotificationBadge && <StyledNotificationBadge />}
                      </Box>
                    </Link>

                    <Link href="/cart" passHref legacyBehavior>
                      <Box
                        as="a"
                        data-testid="main-header-cart-a"
                        ml={4}
                        sx={{
                          position: "relative",
                          display: "flex",
                          top: "-1px",
                        }}
                      >
                        <ShoppingCart width="22" height="24" />
                        {cartCount > 0 && (
                          <StyledCartCount data-testid="main-header-cart-counter">
                            {cartCount}
                          </StyledCartCount>
                        )}
                      </Box>
                    </Link>
                  </Flex>
                </Flex>
              ) : (
                <Flex flex="1" justifyContent="flex-end">
                  {!isLoginPage && (
                    <>
                      <Link
                        data-testid="header-login-btn"
                        href="/sign-in/"
                        passHref
                        legacyBehavior
                      >
                        <Box as="span" mt={3}>
                          <StyledLoginButton as="a" variant="secondary">
                            <Trans>Login</Trans>
                          </StyledLoginButton>
                        </Box>
                      </Link>
                      <Link href="/sign-in/" passHref legacyBehavior>
                        <Box
                          as="a"
                          display={["flex", "flex", "none"]}
                          variant="centered"
                        >
                          <User />
                        </Box>
                      </Link>
                    </>
                  )}
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Content>
    </StyledNavigation>
  );
};

export { Navigation };
