import { createContext } from "react";
import { ApolloQueryResult } from "@apollo/client";
import { Locale, StoreKey } from "lib/Intl";
import { AddedToCartProduct } from "src/types/addedToCardProduct.d";
import { FeatureFlags } from "src/types/featureFlags";
import { PrivateSale } from "src/types/privateSale";
import { UserPreferences, UserPreferencesByEmail } from "src/types/graphql.d";
import { LineItem, Money } from "src/types/ctgraphql.d";
import { CartInfo } from "src/types/cartInfo";
import { StoreSettingsData } from "src/hooks/useStoreSettings";
import { StoreConfig, storeConfigs } from "src/utils/storeConfig";
import getConfig from "next/config";

export enum ViewedProductState {
  UNINITIALIZED = 1,
  LOADING,
  READY,
  ERROR,
}

export type ViewedPageProperties = {
  brand?: string;
  brand_id?: string;
  category_name?: string;
  category_id?: string;
  collection?: string;
  collection_id?: string;
  page_type?: string;
  isProductLoaded?: boolean;
};

export type RankingInfo = {
  abTestID?: string | number;
  abTestVariantID?: string | number;
  indexUsed?: string;
};
export interface AppState {
  locale: Locale;
  storeKey: StoreKey;
  storeConfig: StoreConfig;
  adyenMerchantId: string;
  userPreferences: UserPreferences | UserPreferencesByEmail | undefined;
  setUserPreferences: (
    userPreferences: UserPreferences | UserPreferencesByEmail | undefined
  ) => void;
  isGhostDomain: boolean;
  cartInfo: CartInfo;
  setCartInfo: (id: string, version: number) => void;
  addedProdToCart?: AddedToCartProduct | null | undefined;
  setAddedProdToCart: (products: AddedToCartProduct | null) => void;
  cartMinLineItemsTotalPriceCentAmount: number | null;
  cartMinLineItemsTotalPriceDiff: Money | null;
  setCartMinLineItemsTotalPriceDiff: (
    lineItems: LineItem[] | undefined
  ) => void;
  refetchStoreSettings:
    | (() => Promise<ApolloQueryResult<StoreSettingsData>>)
    | undefined;
  featureFlags: FeatureFlags;
  employeeSaleData: PrivateSale;
  isPrivateSale: boolean;
  collection: { email: string };
  viewedProductState: ViewedProductState;
  setViewedProductState: (state: ViewedProductState) => void;
  displayConsentManager: boolean;
  setDisplayConsentManager: (value: boolean) => void;
  shouldScrollToFooter: boolean;
  homePageMounted: boolean;
  setHomepageMounted: (value: boolean) => void;
  rankingInfo?: RankingInfo;
  initializedConsent?: boolean;
  setInitializedConsent: (value: boolean) => void;
  pageViewedProperties?: ViewedPageProperties;
  setPageViewedProperties: (pageViewedProperties: ViewedPageProperties) => void;
  consentUpdated: boolean;
  setConsentUpdated: (value: boolean) => void;
}

const noop = (): void => {};

const { publicRuntimeConfig } = getConfig();

const initialContext = {
  locale: (publicRuntimeConfig.LOCALE || "en") as Locale,
  storeKey: (publicRuntimeConfig.STORE_KEY || "OT-COM") as StoreKey,
  storeConfig:
    storeConfigs[(publicRuntimeConfig.STORE_KEY || "OT-COM") as StoreKey],
  adyenMerchantId: "",
  userPreferences: undefined,
  setUserPreferences: noop,
  addedProdToCart: undefined,
  cartInfo: {
    id: "",
    version: 0,
  },
  setAddedProdToCart: noop,
  setCartInfo: noop,
  cartMinLineItemsTotalPriceCentAmount: null,
  cartMinLineItemsTotalPriceDiff: null,
  setCartMinLineItemsTotalPriceDiff: noop,
  refetchStoreSettings: undefined,
  isGhostDomain: false,
  employeeSaleData: {
    email: "",
    shopType: [],
  },
  isPrivateSale: false,
  featureFlags: {
    wishlists: false,
    smartBanner: false,
    storeBadges: false,
    referAFriend: false,
    privateSale: false,
    expressCheckout: false,
  },
  collection: { email: "" },
  viewedProductState: ViewedProductState.UNINITIALIZED,
  setViewedProductState: noop,
  displayConsentManager: true,
  setDisplayConsentManager: noop,
  shouldScrollToFooter: false,
  homePageMounted: false,
  setHomepageMounted: noop,
  rankingInfo: {},
  initializedConsent: false,
  setInitializedConsent: noop,
  algoliaSearch: null,
  pageViewedProperties: {},
  setPageViewedProperties: noop,
  consentUpdated: false,
  setConsentUpdated: noop,
};

const AppCtx = createContext<AppState>(initialContext);

AppCtx.displayName = "AppCtx";

export { AppCtx, initialContext };
