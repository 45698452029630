import { ApolloProvider } from "@apollo/react-hooks";
import {
  ApolloClient,
  NormalizedCacheObject,
  ApolloError,
} from "@apollo/client";
import { AppContext } from "next/app";
import { Component } from "react";
import { createApolloClient, OtriumApolloClient } from "src/createApolloClient";
import { CreateApolloClientOptions } from "src/types/apolloClient";
import { FeatureFlags } from "src/types/featureFlags";
import { PrivateSale } from "src/types/privateSale";
import { Intl } from "./Intl";
import sentry from "./sentry";
import { getMetaTags } from "src/modules/page/utils";
import getConfig from "next/config";

interface Props {
  apolloState: NormalizedCacheObject;
  apolloClient?: OtriumApolloClient;
  intl?: Intl;
  featureFlags?: FeatureFlags;
  collection?: { email: string };
  serverAccessToken?: string;
  sessionAccessToken?: string;
  privateSaleData: PrivateSale;
  environmentId?: string;
  adyenMerchantIdData?: string;
}

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your AppComponent via HOC pattern.
 * @param {Function|Class} AppComponent
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function withApollo(AppComponent: any) {
  const { captureException } = sentry();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const displayName =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    AppComponent.displayName || AppComponent.name || "Component";

  // eslint-disable-next-line no-shadow
  return class withApollo extends Component<Props> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    static displayName =
      process.env.NODE_ENV !== "production"
        ? `withApollo(${displayName as string})`
        : displayName;

    static async getInitialProps(appContext: AppContext) {
      const { publicRuntimeConfig } = getConfig();
      const { ctx, AppTree } = appContext;
      const { req, res } = ctx;
      const intl = req?.intl;
      const featureFlags = req?.featureFlags;
      const adyenMerchantIdData = req?.adyenMerchantIdData;
      const collection = req?.collection;
      const privateSaleData = req?.privateSaleData;
      const serverAccessToken = req?.serverAccessToken;
      const environmentId =
        process.env.RELEASE_STAGE !== "production"
          ? req?.query?.environmentId ?? ""
          : "";

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      // eslint-disable-next-line no-shadow
      const apolloClient = (ctx.apolloClient = initApolloClient({
        featureFlags: req?.featureFlags,
        collection: req?.collection,
        privateSaleData: req?.privateSaleData,
        serverAccessToken: req?.serverAccessToken,
        locale:
          publicRuntimeConfig.LOCALE ||
          req?.intl?.locale ||
          process.env.LOCALE ||
          "en",
        storeKey:
          publicRuntimeConfig.STORE_KEY ||
          req?.intl?.storeKey ||
          process.env.STORE_KEY ||
          "OT-COM",
        environmentId,
        adyenMerchantIdData,
      }));

      // Run wrapped getInitialProps methods
      let pageProps = {};
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (AppComponent.getInitialProps) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        pageProps = await AppComponent.getInitialProps(appContext);
      }
      const metaTags = getMetaTags(ctx);

      // Only on the server:
      if (typeof window === "undefined") {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        try {
          const { getDataFromTree } = await import("@apollo/react-ssr");
          // Run all GraphQL queries
          await getDataFromTree(
            <AppTree
              pageProps={pageProps}
              intl={intl}
              featureFlags={featureFlags}
              collection={collection}
              privateSaleData={privateSaleData}
              serverAccessToken={serverAccessToken}
              apolloClient={apolloClient}
              environmentId={environmentId}
              adyenMerchantIdData={adyenMerchantIdData}
            />
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          if (process.env.NODE_ENV === "development") {
            console.error(
              "Error while running `getDataFromTree`",
              error,
              JSON.stringify(error, null, 2)
            );
          }
          // capture exceptions client and server side
          captureException(error, appContext);

          // redirect requests comings from ads
          // that have a had redirect to product-categorie
          // such as /sales/dames/jurken (jurken a cateogy name not brand name but the redirect
          // from saleslading is wrong)
          if (
            res &&
            (error as ApolloError)?.graphQLErrors?.[0]?.message ===
              "Brand not found: "
          ) {
            if (ctx.query.brandOrCategoriesSlug) {
              const { slug, brandOrCategoriesSlug } = ctx.query;
              const prodCategorieUrl = `/${slug as string}/${
                brandOrCategoriesSlug as string
              }`;
              res.writeHead(301, {
                Location: prodCategorieUrl,
              });
              res.end();
            }
          }

          if (
            res &&
            ((error as ApolloError)?.graphQLErrors?.[0]?.message ===
              "No product is found with the provided slug" ||
              ((error as ApolloError)?.graphQLErrors?.[0]?.extensions?.code ===
                "NOT_FOUND" &&
                (error as ApolloError)?.graphQLErrors?.[0]?.path?.[0] ===
                  "productBySlug"))
          ) {
            res.statusCode = 404;
          }
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract();

      return {
        ...pageProps,
        adyenMerchantIdData,
        apolloState,
        intl,
        featureFlags,
        privateSaleData,
        serverAccessToken,
        environmentId,
        collection,
        metaTags,
      };
    }

    render() {
      const {
        apolloState,
        // eslint-disable-next-line no-shadow
        apolloClient,
        featureFlags,
        privateSaleData,
        serverAccessToken,
        intl,
        environmentId,
        collection,
        adyenMerchantIdData,
      } = this.props;

      const client =
        apolloClient ||
        initApolloClient({
          initialState: apolloState,
          featureFlags,
          privateSaleData,
          adyenMerchantIdData,
          locale: intl?.locale || process.env.LOCALE || "en",
          storeKey: intl?.storeKey || process.env.STORE_KEY,
          serverAccessToken,
          environmentId,
          collection,
        });

      return (
        <ApolloProvider client={client}>
          <AppComponent {...this.props} />
        </ApolloProvider>
      );
    }
  };
}

let apolloClient: OtriumApolloClient | undefined;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} options
 */
function initApolloClient(
  options: CreateApolloClientOptions
): ApolloClient<NormalizedCacheObject> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === "undefined") {
    return createApolloClient(options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(options);
  }

  return apolloClient;
}

export { withApollo, initApolloClient };
