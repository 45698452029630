import { NextPageContext } from "next";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { localeDomainMap } from "src/utils/domains";
import { ParsedUrlQuery } from "querystring";

// NOTE: Check Next Auth documentation on how to extend session object type
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */

const authRedirect = (ctx: NextPageContext, session: any): void => {
  if (!session.user.opt_in && !session.user.first_order) {
    (ctx?.res as any)?.cookie("client_opt_in", "1");
  }

  if (ctx.query.signin === "true") {
    return;
  }

  const resolveGender = () => {
    const shopPreference = session?.user?.shop_preference;

    if (
      ctx?.req?.intl?.locale === "en-us" &&
      shopPreference &&
      shopPreference.length &&
      shopPreference[0] === "kids"
    ) {
      return "women";
    }
    if (!shopPreference || !shopPreference.length) {
      return "women";
    } else {
      return shopPreference[0];
    }
  };

  const gender = resolveGender();
  let location = getI18nRoute({
    route: `/${gender}`,
    locale: ctx?.req?.intl?.locale || "en",
  });

  const urls = {
    shop_preference: "/onboarding?stage=shop_preference",
    brand_preference: "/onboarding?stage=brand_preference",
  };

  // FIXME: Object.keys() doesn't guarantee to follow the order of object keys
  // Source: https://stackoverflow.com/a/5525820
  const stages = Object.keys(urls);
  const nextStage =
    stages[
      stages.indexOf(session.user.onboarding_metadata.stage as string) + 1
    ] || stages[0];

  /* Redirect the user to onboarding page when the
   * user hasn't complete or partially complete onboarding flow
   * */

  if (
    session.user.onboarding_metadata.show_onboarding &&
    session.user.onboarding_metadata?.status === "incomplete"
  ) {
    location = urls[nextStage as keyof typeof urls];
  }

  ctx.res?.writeHead(302, {
    location,
  });
  ctx.res?.end();
};

const getUrlParams = (locale: string) => ({
  production: {
    domainWithoutLocale: ["en-us"].includes(locale)
      ? "https://www.us.otrium."
      : "https://www.otrium.",
    ios: {
      isi: "1465868643",
      ibi: "com.otrium.shop",
    },
    android: {
      apn: "com.otrium.shop",
    },
  },
  staging: {
    domainWithoutLocale: ["en-us"].includes(locale)
      ? "https://us-staging.otrium."
      : "https://staging.otrium.",
    ios: {
      isi: "1465868643",
      ibi: "com.otrium.shop.staging",
    },
    android: {
      apn: "com.otrium.shop.staging",
    },
  },
});

const getDomainWithoutLocale = (locale: string) => {
  const isProd = process.env.RELEASE_STAGE === "production";

  return isProd
    ? getUrlParams(locale).production.domainWithoutLocale
    : getUrlParams(locale).staging.domainWithoutLocale;
};

const getMetaTags = (ctx?: NextPageContext) => {
  const origin = ctx?.req
    ? `https://${ctx?.req?.headers.host || ""}`
    : window.location.origin;
  const pathname = ctx?.req?.url || "/";
  const url = new URL(pathname, origin);
  url.pathname = url.pathname.replace(/\.json$/, "");
  const cleanedPathname = url.pathname?.replace(/^\/_next\/data\/[^/]+\//, "/");
  const pathnameWithoutParams = cleanedPathname.split("?")[0];

  const hrefLangs = Object.entries(localeDomainMap).map((localeDomain) => {
    return (
      <link
        rel="alternate"
        href={`${getDomainWithoutLocale(localeDomain[0])}${
          localeDomain[1]
        }${pathnameWithoutParams}`}
        hrefLang={localeDomain[0]}
        key={localeDomain[0]}
      />
    );
  });

  const canonical = (
    <link rel="canonical" href={`${origin}${pathnameWithoutParams}`} />
  );

  const metaTags = (
    <>
      {canonical}
      {hrefLangs}
    </>
  );

  return metaTags;
};

const getQueryString = (query: ParsedUrlQuery) => {
  return Object.keys(query)
    .map((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        return value
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join("&");
      }
      return value
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        : "";
    })
    .filter(Boolean)
    .join("&");
};

export { authRedirect, getUrlParams, getMetaTags, getQueryString };
