import { FC } from "react";
import { Text } from "@otrium/atoms";
import { formatName } from "src/utils/formatName";
import { Wrapper } from "./SearchCategoryCard.styled";
import { Flex } from "@otrium/core";
import {
  BrandGate,
  BrandGateLevelInCustomerPlatform,
} from "src/types/graphql.d";
import { Maybe } from "graphql/jsutils/Maybe";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { useRouter } from "next/router";
import { LinkWrapper } from "../SuggestionItem/SuggestionItem";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import {
  GATED_POPUP_REDIRECT_DATA,
  GATED_POPUP_SOURCE,
  GATED_SEARCH_POPULAR_BRAND,
} from "src/constants/gatedItem";
import { useSelector } from "react-redux";
import { getLocaleGenderId } from "src/modules/page";

interface Props {
  name?: string;
  src: string;
  link: string;
  onClick: () => void;
  gate?: Maybe<BrandGate>;
  hideName?: boolean;
}

const SearchCategoryCard: FC<Props> = ({
  name,
  src,
  link,
  gate,
  onClick,
  hideName,
}) => {
  const { enableGatedHomepage } = useFeatureFlags();
  const isLoggedIn = useIsLoggedIn();
  const { push } = useRouter();
  const localeGenderId = useSelector(getLocaleGenderId);

  const isGatedUser = !!enableGatedHomepage && !isLoggedIn;

  const isGated =
    isGatedUser &&
    (gate?.level === BrandGateLevelInCustomerPlatform.PartiallyGated ||
      gate?.level === BrandGateLevelInCustomerPlatform.FullyGated);

  const handleGatedSuggetionClick = () => {
    void push(`/sign-in?redirect_to=${link}&source=${GATED_POPUP_SOURCE}`);

    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: "Login",
      actionSource: GATED_SEARCH_POPULAR_BRAND,
      brandName: name,
      shopTypeFromRedirect: localeGenderId,
      gateLevel: gate?.level,
    });
  };

  return (
    <LinkWrapper
      link={isGated ? undefined : link}
      onClick={isGated ? handleGatedSuggetionClick : undefined}
    >
      <Wrapper onClick={onClick} data-testid="search-category-card">
        <Flex flex={1} alignItems="center">
          <img src={src} alt={name} />
        </Flex>
        {name && !hideName && (
          <Text
            fontSize={1}
            letterSpacing={"0.4%"}
            fontWeight={600}
            lineHeight={"24px"}
            py={1}
            textAlign="center"
            mt={2}
            color="tone.black"
            as="span"
          >
            {formatName(name)}
          </Text>
        )}
      </Wrapper>
    </LinkWrapper>
  );
};

export default SearchCategoryCard;
