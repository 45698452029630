export const genderTranslations = [
  "women",
  "femme",
  "damen",
  "dames",
  "kobiety",
  "mujer",
  "donna",
  "dame",
  "kvinna",
  "kids",
  "enfant",
  "kinder",
  "kids",
  "ninos",
  "bambini",
  "dzieci",
  "born",
  "barn",
  "men",
  "harren",
  "herren",
  "homme",
  "heren",
  "hombre",
  "uomo",
  "man",
  "mezczyzni",
];

export const brandTranslations = {
  en: "brands",
  pl: "marki",
  be: "merken",
  fr: "marques",
  it: "marchi",
  de: "marken",
  nl: "merken",
  com: "brands",
  us: "brands",
  dk: "maerker",
  se: "varumarken",
  es: "marcas",
  at: "marken",
  "nl-be": "merken",
  "it-it": "marchi",
  "es-es": "marcas",
  "pl-pl": "marki",
  "de-at": "marken",
};

export const allBrandsTranslations = [
  "marques",
  "marken",
  "merken",
  "marki",
  "marcas",
  "marche",
  "brands",
  "maerker",
  "varumarken",
];

export const kidsGenderArr = ["kids-en", "kids", "enfant", "kinder"];

export const kidsSlugs = [
  "boys-en",
  "girls-en",
  "boys",
  "girls",
  "garcon",
  "fille",
  "jungen",
  "madchen",
];
