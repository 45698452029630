export default `

  .skeleton.lazyloaded {
    animation: none;
  }
  
  .skeleton {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: #f5f5f5;
    display: inline-block;
    animation: progress 2s ease infinite;
  }

  @keyframes progress {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.8;
    }
  }

  img.lazyload:not([src]) {
    visibility: hidden;
  }

  /*
  REM unit for optimal font sizing and browser user font setting.
  Setting html font-size: 62.5% for recalculating REM size.
  Because 62.5% of 16px is 10px.
  So 1rem = 10px, 1.5rem = 15px, 2rem = 20px, etc.
  */
 
  *,
  :after,
  :before {
    box-sizing: inherit;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  
@font-face {
  font-family: 'CharisSILW';
  src: url('/static/fonts/CharisSIL-R.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

  .custom-scrollbar::-webkit-scrollbar {
    width: 3px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #ddd;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #000;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #000;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  body {
    color: #717171;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-display: swap;
    letter-spacing: .07rem;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
  }


  @font-face {
    font-family: 'PlusJakartaSans';
    src: url('/static/fonts/PlusJakartaSans-Bold.woff2') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  *:focus {
    outline: none;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  input[type="date"] {
    -webkit-appearance: initial;
    -moz-appearance: initial;
    appearance: initial;
}

  button {
    padding: 0;
  }

  body ::-webkit-scrollbar-track {
    background: rgba(0,0,0,.1);
    border-radius: 0;
  }

  body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0,0,0,.25);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
  }

  /* This styles create a static not hiding scrollbar on MacOS */
  
  /* body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  } */

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  html.no-scroll, body.no-scroll {
    overflow: hidden !important;
  }

  a {
    color: #333333;
    text-decoration: none;
    outline: none;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 0;
  }

  body.dimmed {
    overflow: hidden;
  }

  h2 {
    color: black;
    font-size: 2.4rem;
    font-weight: 700;
  }

  h3 {
    color: black;
    font-size: 1.6rem;
    letter-spacing: 0px;
    font-weight: 600;
  }
  .dimmer {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    overflow: visible;
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: background-color .4s ease, color .4s ease;
    background-color: #0004;
    @media screen and (min-width: 768px) {
      align-items: center;
    }
  }
  
  .pusher.dimmed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: opacity 0.2s ease, background-color 0.2s ease, color 0.2s ease,
        box-shadow 0.2s ease, background 0.2s ease;
    &.dimmed {
      background-color: #0004;
    }
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;
