import styled from "@emotion/styled";
import { Heading } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import BaseButton from "src/atoms/Button/BaseButton";
import { CloseButton } from "src/atoms/CloseButton";

const HeaderWrapper = styled(Flex)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.tone.white};
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100%;
`;

const IconButton = styled(BaseButton)`
  position: absolute;
  height: 100%;
  width: 44px;
  justify-content: center;
  align-items: center;
  left: 0;
  border: none;
  background: transparent;
  display: flex;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  width: 44px;
  justify-content: center;
  right: 0;
  height: 100%;
`;

const Title = styled(Heading)`
  font-weight: 700;
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  font-family: PlusJakartaSans;
  color: ${({ theme }) => theme.colors.tone.black};
  text-align: center;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export { HeaderWrapper, IconButton, StyledCloseButton, Title };
