import styled from "@emotion/styled";
import { Flex } from "@otrium/core";
import BaseButton from "src/atoms/Button/BaseButton";
import { Heading } from "@otrium/atoms";

export const DialogWrapper = styled.div<{ variant: "banner" | "settings" }>`
  position: fixed;
  bottom: 32px;
  left: 16px;
  right: 16px;
  z-index: ${({ theme }) => theme.zIndex.modal};
  max-height: calc(90vh - 32px);

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    bottom: ${({ variant }) => (variant === "banner" ? 0 : "50%")};
    left: ${({ variant }) => (variant === "banner" ? 0 : "50%")};
    right: ${({ variant }) => (variant === "banner" ? 0 : "auto")};
    background-color: ${({ theme, variant }) =>
      variant === "banner" ? theme.colors.tone.white : "transparent"};
    transform: translate(
      ${({ variant }) => (variant === "banner" ? 0 : "-50%")},
      ${({ variant }) => (variant === "banner" ? 0 : "50%")}
    );
    width: ${({ variant }) => (variant === "banner" ? "auto" : "max-content")};
  }
`;

export const Dialog = styled.div<{ variant: "banner" | "settings" }>`
  padding: ${({ theme }) => theme.space.space24};
  background-color: ${({ theme }) => theme.colors.tone.white};
  box-shadow: 0 1px 16px ${({ theme }) => theme.effects.overlay.black_50};
  border-radius: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    border-radius: ${({ variant }) => (variant === "banner" ? 0 : "8px")};
    box-shadow: ${({ variant, theme }) =>
      variant === "banner"
        ? "none"
        : `0 1px 16px ${theme.effects.overlay.black_50}`};
    max-width: ${({ variant }) => (variant === "banner" ? "1264px" : "520px")};
    margin-inline: auto;
    display: flex;
    flex-wrap: ${({ variant }) => (variant === "banner" ? "no-wrap" : "wrap")};
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme, variant }) =>
      variant === "banner" ? theme.space.space32 : theme.space.space16};
  }
`;

export const DialogHeader = styled(Flex)<{ variant: "banner" | "settings" }>`
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.space.space16};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: ${({ variant }) => (variant === "banner" ? "none" : "flex")};
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
  }
`;

export const DialogBody = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.fontSize14};
  // 208px = 48px + 40px + 120px (DialogPadding + DialogHeaderHeightAndMargin + DialogFooterHeightAndMargin)
  max-height: calc(70vh - 208px);
  overflow-y: auto;
  width: 100%;

  & a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const DialogFooter = styled(Flex)<{ variant: "banner" | "settings" }>`
  margin-top: ${({ theme }) => theme.space.space24};
  flex-wrap: wrap;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-top: 0;
    flex-shrink: 0;
    flex-grow: 1;
    width: ${({ variant }) => (variant === "banner" ? "auto" : "100%")};
  }

  & > [data-testid="button-wrapper"] {
    width: calc(50% - 4px);
    max-width: unset;
    transition: width 0ms ease;
    word-wrap: break-word;

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      width: ${({ variant }) =>
        variant === "banner" ? "auto" : "calc(50% - 4px)"};
      min-width: ${({ variant }) =>
        variant === "banner" ? "130px" : "calc(50% - 4px)"};
    }

    &:first-of-type {
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 100%;
      width: 100%;

      button {
        max-width: 100%;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        width: auto;
        min-width: ${({ variant }) =>
          variant === "banner" ? "130px" : "100%"};
        flex-grow: ${({ variant }) => (variant === "banner" ? 0 : 1)};
        flex-shrink: ${({ variant }) => (variant === "banner" ? 1 : 0)};
        flex-basis: ${({ variant }) =>
          variant === "banner" ? "auto" : "100%"};
      }
    }
  }
`;

export const DialogTitle = styled(Heading)`
  font-family: "PlusJakartaSans";
  font-size: ${({ theme }) => theme.fontSizes.fontSize20};
  font-weight: 700;
  text-transform: none;
  letter-spacing: unset;
  line-height: 24px;
`;

export const DialogDescription = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.space16};
`;

export const ConsentList = styled.ul`
  list-style: none;
`;

export const ConsentItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.tone.marble};
  &:last-of-type {
    border-bottom: none;
  }
`;

export const ConsentItemHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  & > label {
    flex-shrink: 0;
  }
`;

export const ConsentItemTitle = styled(BaseButton)<{ expanded: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: left;
  padding-inline: 0;
  padding-block: 16px;
  background: none;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  line-height: 24px;
  max-width: calc(100% - 28px);
  overflow: hidden;

  & > span {
    margin-left: ${({ theme }) => theme.space.space8};
  }

  & > svg {
    transition: transform 300ms ease-in;
    transform: rotate(${({ expanded }) => (expanded ? "90deg" : "0deg")});
    min-width: 8px;
  }

  & > svg g {
    stroke: ${({ theme }) => theme.colors.tone.black};
  }
`;

export const ConsentItemDescription = styled.p`
  margin-bottom: ${({ theme }) => theme.space.space16};
  word-wrap: break-word;
`;
