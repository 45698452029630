import { JSX, ReactNode, useState } from "react";
import { I18nProvider } from "@lingui/react";
import { Messages, setupI18n } from "@lingui/core";
import getConfig from "next/config";

export const TranslationProvider = ({
  messages,
  children,
}: {
  messages?: Messages;
  children: ReactNode;
}): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();

  const [i18n] = useState(() => {
    return setupI18n({
      locale: publicRuntimeConfig.LOCALE || "en",
      messages: { [publicRuntimeConfig.LOCALE || "en"]: messages || {} },
    });
  });

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};
