import Link from "next/link";
import { FC, useMemo } from "react";
import { Image } from "semantic-ui-react";

interface Props {
  locale: string;
}

const BCorpLogo: FC<Props> = ({ locale }) => {
  const bCorpLogoByLocale = useMemo(() => {
    const localeMapping: Record<string, string> = {
      "pl-pl": "en",
      en: "en",
      "en-se": "en",
      "en-dk": "en",
      "en-us": "en",
      "en-gb": "en",
      "nl-be": "en",
      nl: "en",
      de: "de",
      "de-at": "de",
    };
    const normalizedLocale = localeMapping[locale] || "en";

    return `/static/images/Bcorp/Bcorp-${normalizedLocale}.png`;
  }, [locale]);

  return (
    <Link href="https://about.otrium.com/bcorp" passHref>
      <Image width={60} alt="Certified Bcorp Logo" src={bCorpLogoByLocale} />
    </Link>
  );
};

export { BCorpLogo };
