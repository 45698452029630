import { useRouter } from "next/router";
import {
  FC,
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useCart } from "src/hooks/useCart";
import { Locale } from "lib/Intl";
import { Cart } from "src/types/ctgraphql.d";
import { formatStandardPrice } from "src/utils/ct/priceHelper";
import { useMyOrders } from "src/hooks/useMyOrders";
import { GeneralObject, localeAdapter, useSegment } from "src/hooks/useSegment";
import {
  VisitorType,
  getShortCookie,
  getLongCookie,
  setShortCookie,
  setLongCookie,
  getVisitorTypeFromOrders,
} from "src/utils/prospect-vs-customer";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import {
  AppCtx,
  ViewedPageProperties,
  ViewedProductState,
} from "src/contexts/app.context";
import { useSessionChange } from "src/hooks/useSessionChange";
import { getGenderId } from "src/modules/page";
import { useSelector } from "react-redux";
import currencyMap from "src/utils/currencyMap";
import { isEqual } from "lodash-es";
import { useSession } from "next-auth/client";
import { sha256Email } from "src/segment";
import Cookies from "js-cookie";
import { COOKIE_NAME } from "src/molecules/ConsentMangerBanner";
import { CategoryPreferences } from "@ht-sdks/consent-manager/types/types";

interface Props {
  locale: Locale;
}

const getProductCount = (cart: Cart) =>
  cart.lineItems.reduce(
    (count, item) => count + ((item.quantity as number) || 0),
    0
  );

const productPathPattern = /\/product\/\S+/g;

const isProductPath = (path: string) => productPathPattern.test(path);
const isCheckoutPath = (path: string) => path.includes("/checkout");

/**
 * PageViewTracker component
 * `useCart` depends on AppContext so the component needs to be a child of App
 */
const PageViewTracker: FC<Props> = ({ locale }) => {
  const {
    viewedProductState,
    pageViewedProperties,
    setPageViewedProperties,
    isPrivateSale,
    employeeSaleData,
    consentUpdated,
  } = useContext(AppCtx);
  const [session] = useSession();
  const { data: cartData } = useCart();
  const router = useRouter();
  const { segmentPageViewed } = useSegment();
  const cartFetched = useRef(false);
  const { refetch: fetchMyOrders, data: myOrderData } = useMyOrders(1);
  const isLoggedIn = useIsLoggedIn();
  const [logProductPageView, setLogProductPageView] = useState<
    string | undefined
  >();
  const lastTrackedPath = useRef<string>();
  const lastPagedViewedProperties = useRef<GeneralObject>();
  const genderId = useSelector(getGenderId);
  const currency = currencyMap[locale] || "EUR";

  useSessionChange(isPrivateSale, employeeSaleData, locale);

  const setVisitorType = useCallback(() => {
    void (async () => {
      const shortCookie = getShortCookie();
      const longCookie = getLongCookie();
      if (shortCookie) {
        setShortCookie(shortCookie);
      } else if (isLoggedIn) {
        const { data } = await fetchMyOrders();
        const visitorType = getVisitorTypeFromOrders(data);

        setLongCookie(visitorType);
        setShortCookie(visitorType);
      } else if (longCookie) {
        setShortCookie(longCookie);
      } else {
        setLongCookie(VisitorType.PROSPECT);
        setShortCookie(VisitorType.PROSPECT);
      }
    })();
  }, [fetchMyOrders, isLoggedIn]);

  useEffect(() => {
    setVisitorType();
  }, [setVisitorType]);

  useEffect(() => {
    router.events.on("routeChangeComplete", setVisitorType);

    return () => {
      router.events.off("routeChangeComplete", setVisitorType);
    };
  }, [router, setVisitorType]);

  useEffect(() => {
    if (cartData?.cart !== undefined && !cartFetched.current) {
      if (isProductPath(window.location.pathname)) {
        setLogProductPageView(window.location.pathname);
      } else if (
        router.isReady &&
        lastTrackedPath.current !== router.asPath &&
        !isCheckoutPath(window.location.pathname)
      ) {
        lastTrackedPath.current = router.asPath;
        void segmentPageViewedCb(router.asPath);
      }

      cartFetched.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  const segmentPageViewedCb = useCallback(
    (url: string, productAvailable?: boolean) => {
      const hashedEmail = session?.user?.email
        ? sha256Email(session?.user?.email)
        : undefined;

      const isOldCustomer = !!myOrderData?.myOrders?.results?.length;

      const cookiePreferences = Cookies.getJSON(COOKIE_NAME) as
        | { custom?: CategoryPreferences }
        | undefined;

      const consentStatus = cookiePreferences?.custom
        ? Object.keys(cookiePreferences?.custom)
            .filter((key) => cookiePreferences.custom?.[key])
            .join("||")
        : undefined;

      const {
        page_type,
        collection_id,
        collection,
        brand,
        brand_id,
        category_name,
        category_id,
      } = pageViewedProperties as ViewedPageProperties;

      const shouldNotTriggerPageViewed = cookiePreferences
        ? !consentUpdated
        : false;

      if (shouldNotTriggerPageViewed) {
        return;
      }

      return segmentPageViewed({
        pathName: url,
        locale,
        ...(cartData.cart && {
          cartData: {
            product_count: cartData.cart
              ? getProductCount(cartData.cart)
              : undefined,
            cart_total: formatStandardPrice(cartData.cart.totalPrice),
          },
        }),
        productAvailable,
        gtmProperties: {
          page_type: page_type || "other",
          page_location: window.location.href,
          shop_locale: localeAdapter(locale),
          platform: "web",
          shop_currency: currency,
          shop_segment: genderId || "women",
          website_name: "otrium",
          logged_in: isLoggedIn,
          user_id: session?.user?.user_id,
          eid_sha256: hashedEmail,
          consent_status: consentStatus || "functional",
          customer_type: isOldCustomer ? "old customer" : "new customer",
          collection,
          collection_id,
          brand,
          brand_id,
          category_name,
          category_id,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      session,
      segmentPageViewed,
      locale,
      cartData.cart,
      currency,
      genderId,
      isLoggedIn,
      pageViewedProperties,
      consentUpdated,
    ]
  );

  const handleRouteChange = useCallback(
    (url: string) => {
      const checkoutQueryParams = isCheckoutPath(url)
        ? url.split("?")[1]
        : undefined;

      const currentProperties = {
        ...pageViewedProperties,
        user_id: session?.user?.user_id,
        ...(isCheckoutPath(url) && {
          step: checkoutQueryParams?.split("=")[1] || 0,
        }),
      };

      const isProductPageNavigated =
        lastPagedViewedProperties.current?.page_type === "product" &&
        url.includes("/product") &&
        lastTrackedPath.current !== url;

      const isSamePageProperties = isEqual(
        lastPagedViewedProperties.current,
        currentProperties
      );

      const shouldNotLoad = isProductPageNavigated
        ? false
        : lastTrackedPath.current === url ||
          isSamePageProperties ||
          currentProperties.step === 0 ||
          url.includes("?payment_id");

      if (shouldNotLoad) {
        return;
      }
      lastTrackedPath.current = url;
      lastPagedViewedProperties.current = currentProperties;

      if (isProductPath(url)) {
        setPageViewedProperties({
          ...pageViewedProperties,
          isProductLoaded: false,
        });
        setLogProductPageView(url);
      } else if (
        pageViewedProperties?.page_type ===
        lastPagedViewedProperties.current?.page_type
      ) {
        void segmentPageViewedCb(url);
      }
    },
    [
      pageViewedProperties,
      segmentPageViewedCb,
      session?.user?.user_id,
      setPageViewedProperties,
    ]
  );

  useEffect(() => {
    if (
      logProductPageView &&
      (viewedProductState === ViewedProductState.READY ||
        viewedProductState === ViewedProductState.ERROR)
    ) {
      lastTrackedPath.current = router.asPath;
      lastPagedViewedProperties.current = {
        ...pageViewedProperties,
        user_id: session?.user?.user_id,
        isProductLoaded: true,
      };
      void segmentPageViewedCb(
        logProductPageView,
        viewedProductState !== ViewedProductState.ERROR
      );
      setPageViewedProperties({
        ...pageViewedProperties,
        isProductLoaded: true,
      });
      setLogProductPageView(undefined);
    }
  }, [
    segmentPageViewedCb,
    logProductPageView,
    viewedProductState,
    setPageViewedProperties,
    router.asPath,
    pageViewedProperties,
    session?.user?.user_id,
  ]);

  useEffect(() => {
    if (!session) {
      return;
    }

    if (pageViewedProperties && Object.keys(pageViewedProperties).length > 0) {
      handleRouteChange(router.asPath);
    } else {
      router.events.on("routeChangeComplete", handleRouteChange);
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, pageViewedProperties, session]);

  return <Fragment />;
};

export default PageViewTracker;
