import { Trans } from "@lingui/macro";
import { useTheme, Theme } from "@emotion/react";
import { useRouter } from "next/router";
import { FC, useCallback, useState } from "react";
import { Text } from "@otrium/atoms";
import { Box, Flex, SystemCssProperties } from "@otrium/core";
import { LoadingSpinner } from "src/atoms/LoadingSpinner";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { WishlistItemType } from "src/types/graphql.d";

import { addPostLoginWishlistItem } from "src/utils/postLoginWishlist";
import { HeartCardIcon, HeartPageIcon } from "./ToggleWishListButton.styled";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { useSelector } from "react-redux";
import { getIsOpenSearch } from "src/organisms/Header/duck";

export enum WishListIconType {
  HEART_CARD = "Heart-card",
  HEART_PAGE = "Heart-page",
}

export enum WishListButtonType {
  OUTLINED_ICON,
}

interface Props {
  itemType: WishlistItemType;
  buttonType?: WishListButtonType;
  itemTitle?: string;
  itemId: string;
  loading: boolean;
  iconType?: WishListIconType;
  isAddedToWishList?: boolean;
  onAddToWishList: (itemType: WishlistItemType, itemId: string) => void;
  onRemoveWishList: (itemType: WishlistItemType, itemId: string) => void;
  dataTestId?: string;
}

interface WishlistButtonProps {
  itemType: WishlistItemType;
  buttonType?: WishListButtonType;
  itemTitle?: string;
  loading: boolean;
  iconType?: WishListIconType;
  isAddedToWishList: boolean;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  dataTestId?: string;
}

type PathType = {
  fill: string;
  fillOpacity: number;
};

type SVGPropType = {
  path?: PathType;
  "path:nth-of-type(1)"?: PathType;
  "path:nth-of-type(2)"?: PathType;
};

const HeartComponent: FC<{ active: boolean; iconType: WishListIconType }> = ({
  active,
  iconType,
}) => {
  const Component =
    iconType === WishListIconType.HEART_CARD ? HeartCardIcon : HeartPageIcon;
  return (
    <Component
      data-testid={active ? "added-to-wishlist" : "did-not-add-to-wishlist"}
    />
  );
};

export const WishlistButton: FC<WishlistButtonProps> = ({
  itemType,
  itemTitle,
  loading,
  iconType = WishListIconType.HEART_CARD,
  buttonType,
  isAddedToWishList,
  handleClick,
  dataTestId,
}): JSX.Element | null => {
  const isHeartCard = iconType === WishListIconType.HEART_CARD;
  const theme: Theme = useTheme();

  const isIconOnly = itemTitle === undefined;

  const style = {
    border: "none",
    cursor: "pointer",
    background: "transparent",
  };

  const iconCardStateStyle: { "&:hover": SVGPropType } & SVGPropType = {
    "&:hover": {
      "path:nth-of-type(2)": {
        fill: theme.colors.tone.black,
        fillOpacity: 0.5,
      },
    },
  };

  const iconPageStateStyle: { "&:hover": SVGPropType } & SVGPropType = {
    "&:hover": {
      path: {
        fill: theme.colors.tone.black,
        fillOpacity: 0.1,
      },
    },
  };

  if (isAddedToWishList) {
    iconPageStateStyle.path = {
      fill: theme.colors.tone.black,
      fillOpacity: 1,
    };
    iconCardStateStyle["path:nth-of-type(2)"] = {
      fill: theme.colors.tone.black,
      fillOpacity: 1,
    };
  }

  const iconStyle = {
    position: "absolute",
    top: "0",
    right: "0",
    zIndex: 12,
  };

  const iconWithTitleStyle = {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    height: "24px",
  };

  const outlinedIconStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ccc",
    borderRadius: "4px",
    background: "transparent",
    cursor: "pointer",
    height: "48px",
    width: "48px",
    marginLeft: "8px",
  };

  const heartIconWithoutBorderStyle = {
    ...style,
    ...(isIconOnly && buttonType !== WishListButtonType.OUTLINED_ICON
      ? iconStyle
      : iconWithTitleStyle),
  };

  const sx =
    buttonType === WishListButtonType.OUTLINED_ICON
      ? outlinedIconStyle
      : heartIconWithoutBorderStyle;

  return (
    <Flex
      p={
        isIconOnly && buttonType !== WishListButtonType.OUTLINED_ICON
          ? itemType === WishlistItemType.Product
            ? "space8"
            : "space16"
          : "space0"
      }
      mr={isIconOnly ? 0 : 4}
      mb={isIconOnly && !isHeartCard ? 0 : 3}
      as="button"
      onClick={handleClick}
      sx={
        {
          ...sx,
          ...(isHeartCard ? iconCardStateStyle : iconPageStateStyle),
        } as SystemCssProperties
      }
      data-testid={dataTestId}
    >
      {loading ? (
        <Box width="24px" height="24px">
          <LoadingSpinner
            height="24px"
            width="24px"
            data-testid="wishlist-button-loading-spinner"
          />
        </Box>
      ) : (
        <HeartComponent active={isAddedToWishList} iconType={iconType} />
      )}
      {itemTitle && (
        <Text
          fontWeight={"600"}
          fontSize={[0, 1]}
          lineHeight={1.5}
          letterSpacing={"0.4px"}
          color="tone.black"
          ml={2}
        >
          {isAddedToWishList ? (
            <Trans>Following brand</Trans>
          ) : (
            <Trans>Follow brand</Trans>
          )}
        </Text>
      )}
    </Flex>
  );
};

const ToggleWishListButton: FC<Props> = ({
  itemType,
  itemId,
  isAddedToWishList,
  onAddToWishList,
  onRemoveWishList,
  ...restProps
}): JSX.Element | null => {
  const isLoggedIn = useIsLoggedIn();
  const { push, asPath } = useRouter();
  const [addedToWishList, setAddedToWishList] = useState(isAddedToWishList);
  const { enableGatedHomepage } = useFeatureFlags();
  const isOpenSearch = useSelector(getIsOpenSearch);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      if (!isLoggedIn) {
        onAddToWishList(itemType, itemId);
        addPostLoginWishlistItem(itemType, itemId);

        if (!enableGatedHomepage || isOpenSearch) {
          void push(`/sign-in?redirect_to=${asPath}`);
        }
        return;
      }

      if (isAddedToWishList) {
        setAddedToWishList(false);
        onRemoveWishList(itemType, itemId);
        return;
      }
      setAddedToWishList(true);
      onAddToWishList(itemType, itemId);
    },
    [
      isLoggedIn,
      isAddedToWishList,
      onAddToWishList,
      onRemoveWishList,
      itemId,
      itemType,
      push,
      asPath,
      enableGatedHomepage,
      isOpenSearch,
    ]
  );

  if (isLoggedIn && isAddedToWishList === undefined) {
    return null;
  }

  return (
    <WishlistButton
      handleClick={handleClick}
      isAddedToWishList={
        addedToWishList ? addedToWishList : !!isAddedToWishList
      }
      itemType={itemType}
      {...restProps}
    />
  );
};

export default ToggleWishListButton;
