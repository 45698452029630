import { Hit } from "@algolia/client-search";
import {
  SearchResultDisplayedProps,
  SearchResultProduct,
} from "src/hooks/useSegment";
import {
  AlgoliaSearchSuggestion,
  AlgoliaSearchProduct,
} from "src/types/Algolia";
import { ShopType } from "src/types/graphql.d";

export const checkForDuplicates = (
  hits: Hit<AlgoliaSearchSuggestion>[]
): Hit<AlgoliaSearchSuggestion>[] => {
  if (hits.length === 0) {
    return [];
  }
  const duplicates = hits.reduce((obj, next) => {
    if (next.type === "category") {
      if (!obj[next.query]) {
        obj[next.query] = 0;
      }
      obj[next.query]++;
    }
    return obj;
  }, {} as { [key: string]: number });

  return hits.map((hit) => {
    hit.hasDuplicate = (duplicates[hit.query] || 0) > 1;
    return hit;
  });
};

export function getSearchResultDisplayedProps({
  searchTerm,
  products,
  suggestions,
}: {
  searchTerm: string;
  products: Hit<AlgoliaSearchProduct>[];
  suggestions: Hit<AlgoliaSearchSuggestion>[];
}): SearchResultDisplayedProps {
  const collections = suggestions
    .filter((suggestion) => suggestion.type === "collection")
    .map((suggestion) => suggestion.entityID);
  const brands = suggestions
    .filter((suggestion) => suggestion.type === "brand")
    .map((suggestion) => suggestion.entityID);
  const categories = suggestions
    .filter((suggestion) => suggestion.type === "category")
    .map((suggestion) => suggestion.entityID);

  return {
    query: searchTerm,
    num_brands_returned: brands.length,
    num_categories_returned: categories.length,
    num_collections_returned: collections.length,
    num_products_returned: products.length,
    search_result_brands: brands,
    search_result_categories: categories,
    search_result_collections: collections,
    search_result_products: products.map((product, idx) => {
      const hasKidsGender = product.genders.includes(ShopType.Kids);
      const kidsGender = hasKidsGender
        ? product.categories
            .filter(
              (category) =>
                category.slug === "girls" || category.slug === "boys"
            )
            .map((item) => item.name)
        : [];

      return {
        brand: product.brand_name,
        brand_id: product.brand.id,
        category: product.categories.map((cat) => cat.name),
        category_id: product.categories.map((cat) => cat.id),
        labels: product.labels?.map((label) => label.value) || [],
        name: product.name,
        objectID: product.objectID,
        position: idx,
        price: product.price,
        product_discount:
          Math.round(
            (product.regular_price - product.price + Number.EPSILON) * 100
          ) / 100,
        product_id: product.objectID,
        product_kids_gender: kidsGender,
        product_shop_type: product.genders,
        regular_price: product.regular_price,
        sizes_available: product.container_sizes
          .map((size) => size.name)
          .sort(),
        url: `/product/${product.slug}`,
        gate_level: product.gate?.level,
      } as SearchResultProduct;
    }),
  };
}
