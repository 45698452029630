import {
  signIn as signInNextAuth,
  signOut as signOutNextAuth,
} from "next-auth/client";
import { parseUrl, stringifyUrl } from "query-string";
import { AuthMetadata, segment, SignEvent } from "src/tracking/segment";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";

interface Options {
  [key: string]: any;
  callbackUrl?: string;
}

export type AuthType = "sign_in" | "sign_up";

// NOTE: Explicitly pass current domain into 'signIn' and 'signOut' param 'callbackUrl'
// to force redirect to current domain instead of NEXTAUTH_URL.
//
// This fixes the issue of NEXTAUTH_URL doesn't contain 'www' but Cloudflare force adds it. This
// causes redirect of 'callbackUrl'
//
// Possible solutions:
// - Configure Cloudflare to not force add 'www'
// or
// - Add 'www' to NEXTAUTH_URL. We tried to do this, but 'www' wasn't there. Need to figure out why
const getOptionsWithCurrentHost = (defaultOptions?: Options): Options => {
  const options = { ...(defaultOptions || {}) };

  options.callbackUrl = options.callbackUrl
    ? options.callbackUrl.startsWith("/")
      ? `${window.location.origin}${options.callbackUrl}`
      : options.callbackUrl
    : window.location.href;

  return options;
};

// NODE: Need 'singin=true' query param to track start of a session on Client side and
// run login after this.
//
// Unfortunately there is no out-of-the-box way in 'next-auth' to track this. Check this GitHub
// discussion for updates:
// https://github.com/nextauthjs/next-auth/discussions/1634
const getOptionsWithSignInQueryParam = (defaultOptions?: Options): Options => {
  const options = { ...(defaultOptions || {}) };

  const { url, query } = parseUrl(options.callbackUrl as string);

  const callbackUrl = stringifyUrl({
    url,
    query: { ...query, signin: "true" },
  });

  options.callbackUrl = callbackUrl;

  return options;
};

// NOTE: Need this to track a session change in a hook
const getOptionsWithRedirectFalse = (defaultOptions?: Options): Options => {
  const options = { ...(defaultOptions || {}) };
  options.redirect = false;

  return options;
};

const segmentSignHandle = (
  provider: AuthProvider,
  authType: AuthType,
  metadata?: AuthMetadata
): void => {
  switch (provider) {
    case AuthProvider.APPLE:
      segment.signHandler(SignEvent.APPLE_SIGN_IN_STARTED, metadata);
      break;
    case AuthProvider.FACEBOOK:
      segment.signHandler(
        authType === "sign_in"
          ? SignEvent.FACEBOOK_SIGN_IN_STARTED
          : SignEvent.FACEBOOK_SIGN_UP_STARTED,
        metadata
      );
      break;
    case AuthProvider.GOOGLE:
      segment.signHandler(
        authType === "sign_in"
          ? SignEvent.GOOGLE_SIGN_IN_STARTED
          : SignEvent.GOOGLE_SIGN_UP_STARTED,
        metadata
      );
      break;
    case AuthProvider.GUEST_LOGIN:
      segment.signHandler(SignEvent.GUEST_LOGIN_STARTED, metadata);
      break;
    case AuthProvider.CREDENTIALS:
      segment.signHandler(SignEvent.SIGN_IN_FORM_SUBMITTED, metadata);
      break;
    case AuthProvider.CREDENTIALS_REGISTER:
      segment.signHandler(SignEvent.SIGN_UP_FORM_SUBMITTED, metadata);
      break;
    case AuthProvider.CREDENTIALS_SOFT_LOGIN:
      segment.signHandler(SignEvent.SOFT_LOGIN_STARTED, metadata);
      break;
    case AuthProvider.WOO_SHOP_TOKEN:
      segment.signHandler(SignEvent.WOO_SHOP_TOKEN_SIGN_IN_STARTED, metadata);
      break;
  }
};

const signIn: typeof signInNextAuth = (provider, options, ...args) => {
  segmentSignHandle(
    provider as AuthProvider,
    options?.assumedAuthType as AuthType,
    {
      origin: typeof options?.origin === "string" ? options?.origin : undefined,
    }
  );
  delete options?.assumedAuthType;
  delete options?.origin;
  const modifiedOptions = getOptionsWithSignInQueryParam(
    getOptionsWithCurrentHost(options)
  );

  return signInNextAuth(provider, modifiedOptions, ...args);
};

const signOut: typeof signOutNextAuth = (options, ...args) => {
  const modifiedOptions = getOptionsWithRedirectFalse(
    getOptionsWithCurrentHost(options)
  );
  return signOutNextAuth(modifiedOptions, ...args);
};

export { signIn, signOut, getOptionsWithCurrentHost };
