import { useSession } from "next-auth/client";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import { useMemo } from "react";

const useIsLoggedIn = (): boolean => {
  const [session] = useSession();
  const isLoggedIn = useMemo(
    () =>
      !(
        session?.user?.signInPlatform === AuthProvider.CREDENTIALS_SOFT_LOGIN
      ) &&
      !(session?.user?.signInPlatform === AuthProvider.GUEST_LOGIN) &&
      !!session?.accessToken,
    [session?.user?.signInPlatform, session?.accessToken]
  );

  return isLoggedIn;
};

// TODO: Replace the other with this version so we can also pass the isLoading prop
function useNewIsLoggedIn(): {
  isLoggedIn: boolean;
  isSessionLoading: boolean;
} {
  const [session, isSessionLoading] = useSession();
  const isLoggedIn = useMemo(
    () =>
      !(
        session?.user?.signInPlatform === AuthProvider.CREDENTIALS_SOFT_LOGIN
      ) &&
      !(session?.user?.signInPlatform === AuthProvider.GUEST_LOGIN) &&
      !!session?.accessToken,
    [session?.user?.signInPlatform, session?.accessToken]
  );

  return { isLoggedIn, isSessionLoading };
}

export { useIsLoggedIn, useNewIsLoggedIn };
