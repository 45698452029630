import { Iso2LetterLocale, StoreKey } from "lib/Intl";
import { currencyMap, languages } from "./currencySymbols";
import getConfig from "next/config";

export type StoreConfig = {
  locale: string; // NOTE: CT language
  country: string;
  countryCode: Iso2LetterLocale | null;
  shippingAddressCountryCode: Iso2LetterLocale;
  currencyCode: string;
  symbolCode: string;
};
type StoreConfigs = { [key in StoreKey]: StoreConfig };

const { publicRuntimeConfig } = getConfig();

const mappedLocaleStoreKeys: { [key: string]: StoreKey } = {
  en: "OT-COM",
  de: "OT-DE",
  nl: "OT-NL",
  fr: "OT-FR",
  "en-gb": "OT-UK",
  "en-us": "OT-US",
  "de-at": "OT-DE",
  "nl-be": "OT-BE",
  "en-dk": "OT-ZZ",
  "en-se": "OT-ZZ",
  "pl-pl": "pl-PL",
  "es-es": "OT-ES",
  "it-it": "OT-IT",
};

export const storeConfigs: StoreConfigs = {
  "": {
    locale: "en",
    country: "EU",
    countryCode: null,
    shippingAddressCountryCode: "NL",
    currencyCode: currencyMap[languages.en_EU]?.code,
    symbolCode: currencyMap[languages.en_EU]?.symbol,
  },
  "OT-ZZ": {
    locale: "en",
    country: "EU",
    countryCode: null,
    shippingAddressCountryCode: "NL",
    currencyCode: currencyMap[languages.en_EU]?.code,
    symbolCode: currencyMap[languages.en_EU]?.symbol,
  },
  "OT-NL": {
    locale: "nl-NL",
    country: "Netherlands",
    countryCode: "NL",
    shippingAddressCountryCode: "NL",
    currencyCode: currencyMap[languages.nl_NL]?.code,
    symbolCode: currencyMap[languages.nl_NL]?.symbol,
  },
  "OT-BE": {
    locale: "nl-BE",
    country: "Belgium",
    countryCode: "BE",
    shippingAddressCountryCode: "BE",
    currencyCode: currencyMap[languages.nl_BE]?.code,
    symbolCode: currencyMap[languages.nl_BE]?.symbol,
  },
  "OT-DE": {
    locale: "de-DE",
    country: "Germany",
    countryCode: "DE",
    shippingAddressCountryCode: "DE",
    currencyCode: currencyMap[languages.de_DE]?.code,
    symbolCode: currencyMap[languages.de_DE]?.symbol,
  },
  "OT-FR": {
    locale: "fr-FR",
    country: "France",
    countryCode: "FR",
    shippingAddressCountryCode: "FR",
    currencyCode: currencyMap[languages.fr_FR]?.code,
    symbolCode: currencyMap[languages.fr_FR]?.symbol,
  },
  "OT-UK": {
    locale: "en-GB",
    country: "United Kingdom",
    countryCode: "GB",
    shippingAddressCountryCode: "GB",
    currencyCode: currencyMap[languages.en_GB]?.code,
    symbolCode: currencyMap[languages.en_GB]?.symbol,
  },
  "OT-ES": {
    locale: "es-ES",
    country: "Spain",
    countryCode: "ES",
    shippingAddressCountryCode: "ES",
    currencyCode: currencyMap[languages.es_ES]?.code,
    symbolCode: currencyMap[languages.es_ES]?.symbol,
  },
  "OT-IT": {
    locale: "it-IT",
    country: "Italy",
    countryCode: "IT",
    shippingAddressCountryCode: "IT",
    currencyCode: currencyMap[languages.it_IT]?.code,
    symbolCode: currencyMap[languages.it_IT]?.symbol,
  },
  "pl-PL": {
    locale: "pl-PL", // NOTE: Poland storeKey is old format
    country: "Poland",
    countryCode: "PL",
    shippingAddressCountryCode: "PL",
    currencyCode: currencyMap[languages.pl_PL]?.code,
    symbolCode: currencyMap[languages.pl_PL]?.symbol,
  },
  "OT-US": {
    locale: "en-US",
    country: "US",
    countryCode: "US",
    shippingAddressCountryCode: "US",
    currencyCode: currencyMap[languages.en_US]?.code,
    symbolCode: currencyMap[languages.en_US]?.symbol,
  },
  "OT-COM": {
    locale: "en",
    country: "EU",
    countryCode: null,
    shippingAddressCountryCode: "NL",
    currencyCode: currencyMap[languages.en_EU]?.code,
    symbolCode: currencyMap[languages.en_EU]?.symbol,
  },
};

export const getStoreConfig = (storeKey: StoreKey): StoreConfig => {
  return storeConfigs[(publicRuntimeConfig.STORE_KEY as StoreKey) || storeKey];
};

export const getStoreyKey = (locale: string): StoreKey => {
  return (
    (publicRuntimeConfig.STORE_KEY as StoreKey) ||
    mappedLocaleStoreKeys[locale] ||
    "OT-COM"
  );
};
