import useAlgoliaProductsSearch from "./useAlgoliaProductsSearch";
import getFullIndexName from "src/utils/algolia/getFullIndexName";
import { Dispatch, useContext, useEffect, useMemo, useRef } from "react";
import { AppCtx } from "src/contexts/app.context";
import { CatalogBrand, PageContentType, ShopType } from "src/types/graphql.d";
import NoSearchOverlayResults from "src/organisms/Search/NoSearchOverlayResults";
import { SearchWidget } from "src/molecules/SearchWidget";
import { SuggestionItem } from "src/molecules/SuggestionItem";
import { Hit } from "@algolia/client-search";
import {
  AlgoliaSearchProduct,
  AlgoliaSearchSuggestion,
} from "src/types/Algolia";
import getBrandPageRoute from "src/utils/getBrandPageRoute";
import getCollectionsRoute from "src/utils/getCollectionsRoute";
import getProductCategoryRoute from "src/utils/getProductCategoryRoute";
import { SearchResultDisplayedProps, useSegment } from "src/hooks/useSegment";
import { Trans } from "@lingui/macro";
import { ProductCardWishlist } from "src/organisms/ProductCardWishlist";
import useFilterProductLabels from "src/hooks/useFilterProductLabels";
import { useSegmentProductList } from "src/hooks/useSegmentProductList";
import {
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_ID,
  SEGMENT_LIST_TYPE,
} from "src/segment";
import {
  checkForDuplicates,
  getSearchResultDisplayedProps,
} from "src/organisms/Search/utils";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import { GATED_POPUP_REDIRECT_DATA } from "src/constants/gatedItem";
import { ProductRedirect } from "../ProductCardWishlist/ProductCardWishlist";
import type { AbandonedTrackerActions } from "../Header/SubMenu";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";

interface AlgoliaSearchResults {
  searchTerm: string;
  shopType: ShopType;
  dispatchAbandonedTracker: Dispatch<AbandonedTrackerActions>;
  triggerClose: () => void;
}

const SEARCH_CLICK_TYPE_MAP: Record<
  Exclude<AlgoliaSearchSuggestion["type"], "information"> | "product",
  string
> = {
  brand: "brand_id_clicked",
  category: "category_id_clicked",
  collection: "collections_id_clicked",
  product: "product_id_clicked",
};

const AlgoliaSearchResults = ({
  searchTerm,
  shopType,
  dispatchAbandonedTracker,
  triggerClose,
}: AlgoliaSearchResults): JSX.Element | null => {
  const { locale, isGhostDomain } = useContext(AppCtx);
  const { enableAugmentedSearch } = useFeatureFlags();

  const { productsIndexName, suggestionsIndexName } = useMemo(() => {
    const indexName =
      enableAugmentedSearch === "raw"
        ? "products_augmented_search"
        : "products";

    const productsIndexName = getFullIndexName({
      indexName,
      prefix: isGhostDomain ? "zz" : locale,
    });

    const suggestionsIndexName = getFullIndexName({
      indexName: "suggestions",
      prefix: isGhostDomain ? "zz" : locale,
    });

    return { productsIndexName, suggestionsIndexName };
  }, [isGhostDomain, locale, enableAugmentedSearch]);

  const { data, loading } = useAlgoliaProductsSearch({
    query: searchTerm,
    shopType,
    productsIndexName,
    suggestionsIndexName,
  });

  const productsIndex = data?.[productsIndexName];
  const suggestionsIndex = data?.[suggestionsIndexName];
  const { products, suggestions, searchResultDisplayedProps } = useMemo(() => {
    if (loading) {
      return {
        products: [],
        suggestions: [],
        searchResultDisplayedProps: {} as SearchResultDisplayedProps,
      };
    }

    const suggestions = checkForDuplicates(
      (suggestionsIndex?.results || []) as Hit<AlgoliaSearchSuggestion>[]
    );

    const products = (productsIndex?.results ||
      []) as Hit<AlgoliaSearchProduct>[];

    const searchResultDisplayedProps = getSearchResultDisplayedProps({
      searchTerm,
      products,
      suggestions,
    });

    return { products, suggestions, searchResultDisplayedProps };
  }, [loading, suggestionsIndex, productsIndex, searchTerm]);

  const queryID = data?.[productsIndexName].meta.queryID;
  const allProductsCount = data?.[productsIndexName].meta.nbHits;

  const hasSuggestions = suggestions.length > 0;
  const hasProducts = products.length > 0;
  const hasResults = hasSuggestions || hasProducts;

  const { data: labels } = useFilterProductLabels({
    products,
    pageContentType: PageContentType.Category,
  });

  const getRecommendProductsLink = (
    suggestion: AlgoliaSearchSuggestion
  ): string => {
    if (suggestion.slug) {
      switch (suggestion.type) {
        case "brand":
          return getBrandPageRoute(shopType, suggestion.slug, locale);
        case "category":
          return getProductCategoryRoute(shopType, locale, suggestion.slug);
        case "collection":
          return getCollectionsRoute({
            shopType,
            collectionsSlug: suggestion.slug,
            locale,
          });
        default:
          return suggestion.url || "";
      }
    }
    return "";
  };

  const viewSearchResultsLink = useMemo(
    () =>
      hasProducts && products.length === 6
        ? `/${shopType}/search?q=${searchTerm}&q-type=page`
        : undefined,
    [hasProducts, products.length, shopType, searchTerm]
  );

  const {
    listViewAllSelected,
    searchResultDisplayed,
    searchResultClicked,
    productsSearched,
  } = useSegment();
  const listFormat = SEGMENT_LIST_FORMAT.search;
  const listType = SEGMENT_LIST_TYPE.products;
  const listId = SEGMENT_LIST_ID.search;
  const { segmentProductOnClick } = useSegmentProductList({
    algoliaIndex: productsIndexName,
    listFormat,
    listId,
    listType,
    numberOfItems: products?.length || 0,
    queryID,
    shopType,
  });

  const setRedirectData = (
    type: string,
    product?: ProductRedirect,
    actionSource?: string,
    position?: number
  ) => {
    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: type,
      numberOfItems: products?.length || 0,
      product,
      productListMeta: {
        listFormat,
        listId,
        listType,
        shopType,
      },
      actionSource,
      position,
    });
  };
  const onViewAllClick = (position: "header" | "bottom") => {
    dispatchAbandonedTracker({ type: "SET_USER_INTERACTED", payload: true });

    void listViewAllSelected(
      { title: searchTerm, num_items: products?.length || 0 },
      position
    );
  };

  const productSearchTermRef = useRef<string | null>(null);
  const searchTermRef = useRef<string | null>(null);

  useEffect(() => {
    if (searchTerm && searchTerm === productSearchTermRef.current) {
      return;
    }

    // Only fire the tracker if the searchTerm is different
    productSearchTermRef.current = searchTerm;

    void productsSearched(searchTerm);
  }, [searchTerm, productsSearched]);

  const productsRef = useRef<Hit<AlgoliaSearchProduct>[]>([]);
  const suggestionsRef = useRef<Hit<AlgoliaSearchSuggestion>[]>([]);
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (
      loading ||
      !searchTerm ||
      searchTerm === searchTermRef.current ||
      products === productsRef.current ||
      suggestions === suggestionsRef.current
    ) {
      return;
    }

    // Only fire the tracker if the searchTerm is different
    searchTermRef.current = searchTerm;
    productsRef.current = products;
    suggestionsRef.current = suggestions;

    void searchResultDisplayed(searchResultDisplayedProps);
    dispatchAbandonedTracker({
      type: "SET_DATA",
      payload: searchResultDisplayedProps,
    });
  }, [
    loading,
    searchTerm,
    products,
    suggestions,
    searchResultDisplayedProps,
    searchResultDisplayed,
    dispatchAbandonedTracker,
  ]);

  const onSearchResultClick = (
    position: number,
    itemId: string,
    type: Exclude<AlgoliaSearchSuggestion["type"], "information"> | "product",
    gateLevel?: string
  ) => {
    dispatchAbandonedTracker({ type: "SET_USER_INTERACTED", payload: true });

    void searchResultClicked({
      ...searchResultDisplayedProps,
      position_clicked: position,
      gate_level: gateLevel,
      [SEARCH_CLICK_TYPE_MAP[type]]: itemId,
      gated: !isLoggedIn,
    });

    triggerClose();
  };

  return (
    <>
      {hasResults && !loading && (
        <>
          {hasSuggestions && (
            <SearchWidget
              data-testid="search-suggestions"
              isList={false}
              pt={[0, 0, 0, 0, 5]}
            >
              {suggestions.map((suggestion, i) => {
                const link = getRecommendProductsLink(suggestion);
                return (
                  <SuggestionItem
                    key={`${suggestion.type}-${suggestion.entityID}-${i}`}
                    link={link}
                    hit={suggestion}
                    position={i}
                    onClick={onSearchResultClick}
                  />
                );
              })}
            </SearchWidget>
          )}
          {hasProducts && (
            <SearchWidget
              data-testid="search-products"
              header={<Trans>Products</Trans>}
              viewAllText={<Trans>Show {allProductsCount} results</Trans>}
              viewAllLink={viewSearchResultsLink}
              viewAllOnClick={onViewAllClick}
              scrolled={false}
              pt={[3, 3, 3, 5]}
              pb={[3, 3, 3, "space48"]}
              withBottomButton
              triggerClose={triggerClose}
            >
              {products.map((product, index) => (
                <ProductCardWishlist
                  key={`${product.objectID}-${index}`}
                  product={{
                    ...product,
                    id: product.objectID,
                    name: product.name,
                    price: product.price,
                    regular_price: product.regular_price,
                    brand: {
                      name: product.brand_name || "",
                    } as CatalogBrand,
                    thumbnail: product.image || "",
                    labels: labels[product.slug],
                    available_sizes_label: "",
                    images: product.brand?.images || [],
                  }}
                  position={index}
                  segmentProductOnClick={segmentProductOnClick}
                  setVisibleProducts={() => {}}
                  queryID={queryID}
                  algoliaIndex={productsIndexName}
                  setProductRedirect={setRedirectData}
                  onSearchProductClick={onSearchResultClick}
                  {...product}
                />
              ))}
            </SearchWidget>
          )}
        </>
      )}
      {!hasResults && !loading && (
        <NoSearchOverlayResults
          searchTerm={searchTerm}
          shopType={shopType}
          triggerClose={triggerClose}
          dispatchAbandonedTracker={dispatchAbandonedTracker}
        />
      )}
    </>
  );
};

export default AlgoliaSearchResults;
