import { SearchWidget } from "src/molecules/SearchWidget";
import { Trans } from "@lingui/macro";
import { CarouselType } from "src/types/Carousels.d";
import { SearchCategoryCard } from "src/molecules/CategoryCard";
import { useCallback, useContext } from "react";
import useDefaultSearchCarousels from "src/hooks/useDefaultSearchCarousels";
import { useSegment } from "src/hooks/useSegment";
import getBrandPageRoute from "src/utils/getBrandPageRoute";
import { ShopType } from "src/types/graphql.d";
import { AppCtx } from "src/contexts/app.context";

interface Props {
  shopType: ShopType;
  animationTime?: number;
}

const DefaultSearch = ({
  shopType,
  animationTime,
}: Props): JSX.Element | null => {
  const { locale } = useContext(AppCtx);
  const { data: defaultSearchCarousels, loading } = useDefaultSearchCarousels({
    shop_type: shopType,
  });

  const { segmentViewSearchAllBrands, segmentSearchRecommendationClicked } =
    useSegment();
  const handleSearchRecommendationClick = useCallback(
    (data: { type: CarouselType; name: string; position: number }) => {
      const { type, name, position } = data;
      void segmentSearchRecommendationClicked(type, name, position);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return !loading ? (
    <>
      {(
        defaultSearchCarousels?.defaultSearchCarousels?.brand_carousels || []
      ).map(({ title, items, view_all_url, position }, index) => (
        <SearchWidget
          key={index}
          data-testid="search-popular-brands"
          py={[3, 3, 3, 5]}
          bg={["tone.sand", "tone.sand", "tone.sand", "tone.sand", "White"]}
          position={position}
          viewAllText={view_all_url ? <Trans>View all</Trans> : undefined}
          viewAllLink={view_all_url}
          header={title}
          animationDuration={(animationTime || 0) * 1.3}
          widgetType={CarouselType.Brand}
          viewAllOnClick={() => {
            void segmentViewSearchAllBrands();
          }}
        >
          {items.map(({ id, logo, name, slug, gate }, i) => (
            <SearchCategoryCard
              key={`${id}-${i}`}
              src={logo}
              link={getBrandPageRoute(shopType, slug, locale)}
              gate={gate}
              name={name}
              onClick={() =>
                handleSearchRecommendationClick({
                  type: CarouselType.Brand,
                  name,
                  position: i,
                })
              }
              hideName
            />
          ))}
        </SearchWidget>
      ))}
      {(
        defaultSearchCarousels?.defaultSearchCarousels?.edit_carousels || []
      ).map(({ title, items, view_all_url, position }, index) => (
        <SearchWidget
          data-testid={`search-carousel-edit-${index}`}
          key={index}
          position={position}
          viewAllText={view_all_url ? <Trans>View all</Trans> : undefined}
          viewAllLink={view_all_url}
          header={title}
          py={[3, 3, 3, 5]}
          mt={[4, 4, 4, 0]}
          animationDuration={(animationTime || 0) * 1.3}
          widgetType={CarouselType.Edit}
        >
          {items.map(({ id, name, image, url }, i) => (
            <SearchCategoryCard
              key={`${id}-${i}`}
              src={image}
              name={name}
              link={url}
              onClick={() =>
                handleSearchRecommendationClick({
                  type: CarouselType.Edit,
                  name,
                  position: i,
                })
              }
            />
          ))}
        </SearchWidget>
      ))}
    </>
  ) : null;
};

export default DefaultSearch;
