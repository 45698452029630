import { Box } from "@otrium/core";
import styled from "@emotion/styled";

const Content = styled(Box)`
  min-width: 0;
  width: calc(100vw - (100vw - 100%));
  max-width: 1264px;
  margin: 0 auto;
  padding: ${({ theme }) =>
    `${theme.space?.space0 || "0px"} ${theme.space?.space16 || "16px"}`};
`;

const ContentMobileFullscreen = styled(Content)`
  &.no-padding {
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.space.space16};
  }
`;

export { Content, ContentMobileFullscreen };
