import {
  LineItem,
  Money,
  RawCustomField,
  RawProductAttribute,
} from "src/types/ctgraphql.d";
import { formatStandardPrice } from "./priceHelper";
import { StoreConfig } from "../storeConfig";
import { getRegularPrice } from "../getRegularPrice";

export type Labels = Array<{
  type: string;
  value: string;
}>;

export const itemLabels = (
  labels: Labels
): Array<{ label_id: string; label_name: string }> =>
  ((labels as unknown as Labels) || []).map(
    ({ type, value }: { type: string; value: string }) => {
      return { label_id: type, label_name: value };
    }
  );

export const getCTAttribute = (
  name: string,
  attributesRaw?: RawProductAttribute[]
): string | undefined =>
  attributesRaw &&
  (attributesRaw.find((f) => f.name === name)?.value as string | undefined);

export const getCTCustomField = (
  name: string,
  customFieldsRaw: RawCustomField[] | undefined | null
): unknown =>
  customFieldsRaw &&
  (customFieldsRaw?.find((f) => f.name === name)?.value as unknown);

export const getTrackingCartItems = (
  lineItems: Array<LineItem>,
  storeConfig: StoreConfig
): Array<any> => {
  return lineItems.map((item) => {
    const attributesRaw = item.variant?.attributesRaw;

    return {
      item_id: getCTAttribute("woo_product_id", attributesRaw),
      item_sku: item?.variant?.sku,
      item_name: getCTAttribute("name", attributesRaw),
      item_variant_sku: item?.variant?.sku,
      item_variant_name: getCTAttribute("size_value", attributesRaw),
      brand_id: getCTAttribute("woo_brand_id", attributesRaw),
      brand_name: getCTAttribute("brand_name", attributesRaw),
      quantity: item?.quantity as string,
      price: formatStandardPrice(item?.price?.value as Money),
      retail_price: formatStandardPrice(getRegularPrice(item, storeConfig)),
      discount_value: "",
      discount_type: "",
      item_custom_labels: itemLabels(
        getCTAttribute("labels", attributesRaw) as unknown as Labels
      ),
    };
  });
};
