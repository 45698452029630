import { FC } from "react";
import { Box, Flex, useBreakpoints } from "@otrium/core";
import { useTheme, Theme } from "@emotion/react";
import { ThuiswinkelWaarborgIconWhite } from "src/icons/ThuiswinkelWaarborgIconWhite";
import { MastercardIconWhite } from "src/icons/MastercardIconWhite";
import { IdealIconWhite } from "src/icons/IdealIconWhite";
import { PaypalIconWhite } from "src/icons/PaypalIconWhite";
import { VisaIconWhite } from "src/icons/VisaIconWhite";
import { AmexIconWhite } from "src/icons/AmexIconWhite";
import { ApplePayIconWhite } from "src/icons/ApplePayIconWhite";
import { BancontactWhite } from "src/icons/BancontactWhite";
import { CartaSiIconWhite } from "src/icons/CartaSiIconWhite";
import { MaestroIconWhite } from "src/icons/MaestroIconWhite";
import { PostepayIconWhite } from "src/icons/PostepayIconWhite";
import { Przelewy24IconWhite } from "src/icons/Przelewy24IconWhite";
import { SofortIconWhite } from "src/icons/SofortIconWhite";

import { TrustpilotCustom } from "src/molecules/TrustpilotBox";
import { Locale } from "lib/Intl";
import { Divider } from "src/atoms/Divider";
import { Wrapper, IconWrapper } from "./FooterHighlight.styled";
import { Container } from "./Footer.styled";
import { BCorpLogo } from "src/atoms/BCorpLogo/BCorpLogo";

const PaypalIcon = () => <PaypalIconWhite data-testid="paypal-icon" />;
const Ideal = () => <IdealIconWhite data-testid="ideal-icon" />;
const Amex = () => <AmexIconWhite data-testid="amex-icon" />;
const TwIcon = () => <ThuiswinkelWaarborgIconWhite data-testid="tw-icon" />;
const Bancontact = () => <BancontactWhite data-testid="bancontact-icon" />;
const Visa = () => <VisaIconWhite data-testid="visa-icon" />;
const ApplePay = () => <ApplePayIconWhite data-testid="apple-pay-icon" />;
const MastercardIcon = () => (
  <MastercardIconWhite data-testid="mastercard-icon" />
);
const Maestro = () => <MaestroIconWhite data-testid="maestro-icon" />;

const PaymentsFr = () => {
  return (
    <>
      <PaypalIcon />
      <Visa />
      <MastercardIcon />
    </>
  );
};

const PaymentsUs = () => {
  return (
    <>
      <PaypalIcon />
      <Visa />
      <MastercardIcon />
      <Amex />
    </>
  );
};

const PaymentsDe = () => {
  return (
    <>
      <SofortIconWhite data-testid="sofort-icon" />
      <PaypalIcon />
      <Visa />
      <MastercardIcon />
    </>
  );
};
const PaymentsSeDk = () => {
  return (
    <>
      <PaypalIcon />
      <Visa />
      <MastercardIcon />
      <Amex />
      <ApplePay />
    </>
  );
};
const PaymentsEs = () => {
  return (
    <>
      <Visa />
      <MastercardIcon />
      <PaypalIcon />
      <ApplePay />
      <Maestro />
      <Amex />
    </>
  );
};
const PaymentsIt = () => {
  return (
    <>
      <Visa />
      <MastercardIcon />
      <PaypalIcon />
      <ApplePay />
      <Amex />
      <CartaSiIconWhite data-testid="carta-si-icon" />
      <PostepayIconWhite data-testid="poste-pay-icon" />
    </>
  );
};
const PaymentsPl = () => {
  return (
    <>
      <Visa />
      <MastercardIcon />
      <PaypalIcon />
      <Amex />
      <ApplePay />
      <Przelewy24IconWhite data-testid="przelewy-24-icon" />
    </>
  );
};
const PaymentsBe = () => {
  return (
    <>
      <MastercardIcon />
      <Ideal />
      <Bancontact />
      <PaypalIcon />
      <Visa />
    </>
  );
};
const PaymentsNl = () => {
  return (
    <>
      <Box display={["none", "none", "none", "block"]}>
        <a
          rel="noopener noreferrer"
          href="https://www.thuiswinkel.org/leden/otrium-nl/certificaat/"
          target="_blank"
        >
          <TwIcon />
        </a>
      </Box>
      <MastercardIcon />
      <Ideal />
      <Bancontact />
      <PaypalIcon />
      <Visa />
    </>
  );
};

interface Props {
  locale: Locale;
}

export const FooterHighlight: FC<Props> = ({ locale }) => {
  const theme: Theme = useTheme();
  const { isMobile } = useBreakpoints();

  return (
    <Wrapper alignSelf="center" data-testid="footer-top-payments">
      <Container display={["block", "block", "block", "none"]}>
        <Flex
          variant="centered"
          justifyContent={["space-between", "space-between", "space-around"]}
        >
          {["nl"].includes(locale) && !isMobile && (
            <a
              rel="noopener noreferrer"
              href="https://www.thuiswinkel.org/leden/otrium-nl/certificaat/"
              target="_blank"
            >
              <TwIcon />
            </a>
          )}
          <TrustpilotCustom locale={locale} />
          <BCorpLogo locale={locale} />
        </Flex>
        <Box my={"23px"}>
          <Divider color={theme.colors.tone.anthraciteDark} />
        </Box>
      </Container>
      <Container>
        <IconWrapper>
          {["fr", "en-gb"].includes(locale) && <PaymentsFr />}
          {["en-us"].includes(locale) && <PaymentsUs />}
          {["de", "de-at"].includes(locale) && <PaymentsDe />}
          {["en-se", "en-dk"].includes(locale) && <PaymentsSeDk />}
          {["es-es", "es"].includes(locale) && <PaymentsEs />}
          {["it-it", "it"].includes(locale) && <PaymentsIt />}
          {["pl-pl", "pl"].includes(locale) && <PaymentsPl />}
          {["nl-be", "en"].includes(locale) && <PaymentsBe />}
          {["nl"].includes(locale) && <PaymentsNl />}
        </IconWrapper>
      </Container>
    </Wrapper>
  );
};
