import styled from "@emotion/styled";
import { Heading } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import { Container as MainContainer } from "src/atoms/Container";

const StyledFooterTop = styled.div`
  display: flex;
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 12px 20px 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.platinumLight};
  min-height: 78px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-flow: row wrap;
    padding: 8px 20px;
  }
`;

const Wrapper = styled.footer`
  background: ${({ theme }) => theme.colors.tone.black};
  color: ${({ theme }) => theme.colors.tone.white};
  padding: 0 0 24px 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: 0 0 32px 0;
  }
`;

const MiddleWrapper = styled(Flex)`
  flex-direction: column-reverse;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints[2]}) and (max-width: ${({ theme }) =>
      theme.breakpoints[3]}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Container = styled(MainContainer)`
  padding: 0 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 0 20px;
  }
`;

const Title = styled(Heading)`
  font-family: "PlusJakartaSans", sans-serif;
  font-size: 20px;
  text-transform: none;
  color: ${({ theme }) => theme.colors.tone.white};
  line-height: 1.33;
  letter-spacing: normal;
`;

export { StyledFooterTop, Wrapper, MiddleWrapper, Container, Title };
