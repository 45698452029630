import styled from "@emotion/styled";
import { Box } from "@otrium/core";

const Wrapper = styled(Box)`
  width: 100%;
  background: #1d1e20;
  margin-left: -1px;
  padding: 0 32px 27px;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 14px 0 20px;
  }
`;

const List = styled(Box)`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
    font-size: ${({ theme }) => theme.fontSizes[0]};
    letter-spacing: 0.02rem;
    width: 100%;

    &:not(.trustpilot) svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;

      path {
        stroke: ${({ theme }) => theme.colors.primary.gold};
      }
    }

    > span {
      margin-left: 10px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    li {
      width: 50%;
    }
  }
  @media screen and (max-width: 767px) {
    li:not(.trustpilot) {
      margin-bottom: ${({ theme }) => theme.space[3]}px;
      > span {
        margin-left: ${({ theme }) => theme.space[3]}px;
      }
    }
    li:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    li {
      width: auto;
      justify-content: flex-start;
      text-align: left;
      line-height: 3rem;
    }
    li:not(:first-of-type) {
      margin-left: 16px;
    }
  }

  @media screen and (max-width: 999px) {
    li:last-of-type {
      display: none;
    }
    li.trustpilot {
      display: none;
    }
  }
`;

export { Wrapper, List };
