import { FC, useEffect } from "react";
import { Trans } from "@lingui/macro";
import {
  ContentWrapper,
  ModalWrapper,
  StyledButton,
  Image,
} from "./LoginRegisterModal.styled";
import { Modal } from "src/atoms/Modal";
import { Text, Heading } from "@otrium/atoms";
import { Box, useBreakpoints } from "@otrium/core";
import { useTheme, Theme } from "@emotion/react";
import { CloseButton } from "src/atoms/CloseButton";
import { useRouter } from "next/router";
import { GatedPopupData, useSegment } from "src/hooks/useSegment";
import { GATED_POPUP_SOURCE } from "src/constants/gatedItem";

interface LoginRegisteModalProps {
  open?: boolean;
  onClose: () => void;
  redirectTo?: string;
  trackingData: GatedPopupData;
  onRedirect?: (type: string) => void;
}

const LoginRegisterModal: FC<LoginRegisteModalProps> = ({
  open = false,
  onClose,
  redirectTo = "",
  trackingData,
  onRedirect,
}) => {
  const theme: Theme = useTheme();
  const { push, asPath } = useRouter();
  const { isMobile } = useBreakpoints();
  const {
    segmentGatedPopupOpened,
    segmentGatedPopupLoginClicked,
    segmentGatedPopupRegisterClicked,
    segmentGatedPopupClosed,
  } = useSegment();

  useEffect(() => {
    if (open) {
      void segmentGatedPopupOpened(trackingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    void segmentGatedPopupClosed(trackingData);
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      fullHeight={false}
      centerOnScreen={true}
      rounded={true}
      customWidth={"90%"}
    >
      <ModalWrapper>
        {isMobile ? (
          <Image
            src={"/static/images/gated_popup_image_mobile.jpeg"}
            alt={"gated popup image mobile"}
          />
        ) : (
          <Image
            src={"/static/images/gated_popup_image.jpeg"}
            alt={"gated popup image desktop"}
          />
        )}

        <CloseButton
          fill={theme?.colors?.tone?.white}
          onClick={handleClose}
          sx={{ position: "absolute", right: "26px", top: "26px" }}
        />
        <ContentWrapper>
          <Heading
            my={2}
            as="h2"
            variant="h2"
            fontSize="32px"
            fontFamily="PlusJakartaSans"
            letterSpacing="normal"
            lineHeight="40px"
            pb={0}
            sx={{
              textTransform: "none",
            }}
          >
            <Trans>Log in to access ALL fashion deals</Trans>
          </Heading>
          <Text
            variant="body.source.regular16"
            color="tone.anthracite"
            as="p"
            textAlign="center"
            lineHeight="24px"
            mb={16}
            sx={{
              letterSpacing: 0,
            }}
          >
            <Trans>
              Unlock the best fashion deals from your favourite brands
            </Trans>
          </Text>
          <Box mb={8}>
            <StyledButton
              variant="primary"
              colorScheme="dark"
              data-testid="login-button"
              onClick={() => {
                if (onRedirect) {
                  onRedirect("Login");
                }
                void segmentGatedPopupLoginClicked(trackingData);
                void push(
                  `/sign-in?redirect_to=${
                    redirectTo ? redirectTo : asPath
                  }&source=${GATED_POPUP_SOURCE}`
                );
              }}
            >
              <Trans>Log in</Trans>
            </StyledButton>
          </Box>
          <StyledButton
            variant="secondary"
            colorScheme="dark"
            data-testid="register-button"
            onClick={() => {
              if (onRedirect) {
                onRedirect("Register");
              }
              void segmentGatedPopupRegisterClicked(trackingData);
              void push(
                `/register?redirect_to=${
                  redirectTo ? redirectTo : asPath
                }&source=${GATED_POPUP_SOURCE}`
              );
            }}
          >
            <Trans>Register</Trans>
          </StyledButton>
        </ContentWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default LoginRegisterModal;
