/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
type ReducerMap<S, A extends ReducerAction<string, unknown>> = {
  [K in A["type"]]: (
    state: S,
    payload: Extract<A, { type: K }>["payload"]
  ) => S;
};

const getReducer = <T>(reducerKey: keyof T, reducerList: T) =>
  reducerList[reducerKey];

export type ReducerAction<T extends string, P = unknown> = {
  type: T;
  payload: P extends void | undefined ? void : P;
};

export type Dispatch<A> = (action: A) => void;

export const createReducer =
  <S, A extends ReducerAction<string>>(actions: ReducerMap<S, A>) =>
  (state: S, action: A) =>
    getReducer(action.type, actions)(state, action.payload);
