import { FC, ReactNode } from "react";
import Link from "next/link";
import { AlgoliaSearchSuggestion } from "src/types/Algolia";
import { Box, Flex } from "@otrium/core";
import {
  Highlighted,
  SuggestionType,
  Wrapper,
  DividerDot,
  Breadcrumb,
  SuggestionLink,
} from "./SuggestionItem.styled";
import { sanitize } from "isomorphic-dompurify";
import { useLingui } from "@lingui/react";
import { LockIcon } from "@otrium/icons";
import { theme } from "src/theme";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { BrandGateLevelInCustomerPlatform } from "src/types/graphql.d";
import { useRouter } from "next/router";
import {
  GATED_POPUP_REDIRECT_DATA,
  GATED_POPUP_SOURCE,
  GATED_SEARCH_SUGGESTION_ITEM,
} from "src/constants/gatedItem";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import { useSelector } from "react-redux";
import { getLocaleGenderId } from "src/modules/page";

interface Props {
  link: string;
  hit: AlgoliaSearchSuggestion;
  position: number;
  onClick?: (
    position: number,
    itemId: string,
    type: Exclude<AlgoliaSearchSuggestion["type"], "information">
  ) => void;
}

const SUGGESTION_TYPE_I18N_KEYS: Record<
  AlgoliaSearchSuggestion["type"],
  string
> = {
  brand: "Brand",
  category: "Category",
  collection: "Collection",
  information: "Information",
};

export const LinkWrapper: FC<{
  link?: string;
  children: ReactNode;
  onClick?: () => void;
}> = ({ link, children, onClick }) => {
  return link ? (
    <Link href={link} passHref legacyBehavior>
      {children}
    </Link>
  ) : (
    <Box as={"a"} onClick={onClick}>
      {children}
    </Box>
  );
};

const SuggestionItem: FC<Props> = ({ link, hit, position, onClick }) => {
  const { i18n } = useLingui();
  const { enableGatedHomepage } = useFeatureFlags();
  const isLoggedIn = useIsLoggedIn();
  const { push } = useRouter();
  const localeGenderId = useSelector(getLocaleGenderId);

  const isGatedUser = !!enableGatedHomepage && !isLoggedIn;

  const isGated =
    isGatedUser &&
    (hit?.gate?.level === BrandGateLevelInCustomerPlatform.PartiallyGated ||
      hit?.gate?.level === BrandGateLevelInCustomerPlatform.FullyGated);

  const handleClick = () => {
    if (onClick && hit.type !== "information") {
      onClick(position, hit.entityID, hit.type);
    }
  };

  const handleGatedSuggetionClick = () => {
    void push(`/sign-in?redirect_to=${link}&source=${GATED_POPUP_SOURCE}`);

    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: "Login",
      actionSource: GATED_SEARCH_SUGGESTION_ITEM,
      brandName: hit.query,
      shopTypeFromRedirect: localeGenderId,
      gateLevel: hit.gate?.level,
    });
  };

  return (
    <LinkWrapper
      link={isGated ? undefined : link}
      onClick={isGated ? handleGatedSuggetionClick : undefined}
    >
      <SuggestionLink onClick={handleClick} data-testid="suggestion-link">
        <Wrapper>
          <Flex alignItems="center">
            <Highlighted
              dangerouslySetInnerHTML={{
                /* eslint-disable no-underscore-dangle */
                __html: sanitize(hit._highlightResult.query?.value) || "",
                /* eslint-enable no-underscore-dangle */
              }}
            />
            {isGated && (
              <LockIcon
                color={theme.colors.tone.black}
                width={22}
                height={16}
                style={{
                  paddingLeft: "6px",
                }}
              />
            )}
          </Flex>

          <SuggestionType display={["none", "none", "none", "block"]}>
            -
          </SuggestionType>
          <SuggestionType>
            {i18n._(SUGGESTION_TYPE_I18N_KEYS[hit.type || "information"])}
          </SuggestionType>
        </Wrapper>
        {hit.hasDuplicate && (
          <Flex alignItems="center">
            {hit.parent_path.map((name, i) => (
              <Flex alignItems="center" key={`breadcrumb${i}`}>
                <Breadcrumb
                  dangerouslySetInnerHTML={{ __html: sanitize(name) }}
                />
                {i < hit.parent_path.length - 1 && <DividerDot />}
              </Flex>
            ))}
          </Flex>
        )}
      </SuggestionLink>
    </LinkWrapper>
  );
};

export default SuggestionItem;
