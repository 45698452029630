import { FC } from "react";
import { Done } from "src/icons/Done";
import { Box } from "@otrium/core";
import { useTheme, Theme } from "@emotion/react";
import { Divider } from "src/atoms/Divider";
import { Locale } from "lib/Intl";
import { TrustpilotCustom } from "src/molecules/TrustpilotBox";
import { List, Wrapper } from "./FooterFeatures.styled";
import { Container } from "./Footer.styled";
import { useMiscellaneous } from "src/hooks/useMiscellaneous";
import NoSSR from "src/atoms/NoSSR";
import { BCorpLogo } from "src/atoms/BCorpLogo/BCorpLogo";

interface Props {
  locale: Locale;
}

export const FooterFeatures: FC<Props> = ({ locale }) => {
  const theme: Theme = useTheme();
  const { data: miscellaneousData } = useMiscellaneous();

  return (
    <Wrapper alignSelf="center" display="block">
      <Container>
        <Box mb={"23px"}>
          <Divider color={theme.colors.tone.anthraciteDark} />
        </Box>
        <List as="ul" aria-label="Our advantages">
          {miscellaneousData?.miscellaneous?.footer?.map((footer, index) => (
            <li key={index}>
              <NoSSR>
                <Done role="presentation" />
              </NoSSR>
              <span>{footer}</span>
            </li>
          ))}
          <li className="trustpilot">
            <TrustpilotCustom locale={locale} />
          </li>
          <li>
            <BCorpLogo locale={locale} />
          </li>
        </List>
      </Container>
    </Wrapper>
  );
};
